.list-box__heading {
    display: flex;
    margin-bottom: 3.5rem;
}

.list-bot__heading-_title {
    padding: 0;
    margin: 0;
}

.list-box__heading__date {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    gap: 0.3rem;
    flex: 2;
}

.list-box__heading__day, .list-box__heading__month, .list-box__heading__year {
    font-size: 0.7rem;
    color: gray;
}

.list-box__heading__add-contact {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    color: gray;
    font-size: .9rem;
    cursor: pointer;
    height: 25px;
}

.popper {
    opacity: 1;
    background-color: white;
    border: 0px solid lightgray;
    border-radius: 0.2rem;
    width: 200px;
    z-index: 999;
    box-shadow: 1px 1px 15px rgba(0,0,0, 0.1);
}

#arrow,
#arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: '';
  transform: rotate(45deg);
}

.popper[data-popper-placement^='top'] > #arrow {
    bottom: -4px;
}
  
.popper[data-popper-placement^='bottom'] > #arrow {
    top: -4px;
}
  
.popper[data-popper-placement^='left'] > #arrow {
    right: -4px;
}
  
.popper[data-popper-placement^='right'] > #arrow {
left: -4px;
}

.menu {
    list-style: none;
    padding: 0;
    font-size: .9rem;
    text-align: center;
    vertical-align: middle;
    margin: 0;
}

.menu_item {
    padding: 1rem 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.menu_item:hover {
    background-color: lightgray;
    cursor: pointer;
}

.menu_separator {

    border-bottom: 1px solid #ddd;
}

.download-template {
    font-size: 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.2rem;
}

.download-template > a {
    color: rgb(60, 189, 75);
}
.download-template > a:active {
    text-decoration: none;
}
