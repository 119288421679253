.notes {
    flex: 2;
    min-height: 150px;
    padding-top: 5px;
    font-size: 14px;
    /* overflow: scroll; */
    /* color: rgb(153, 153, 153); */
    overflow-x: hidden;
    background-color: white;
    padding: 0 1.5rem;
    scroll-behavior: smooth;
}

/* .list-container {
    flex: 2;
    min-height: 150px;
    margin: 5px 0 0;
    padding-top: 5px;
    font-size: 14px;
    overflow: scroll;
    color: rgb(153, 153, 153);
    overflow-x: hidden;
    background-color: white;
    padding: 0 1.5rem;
    scroll-behavior: smooth;
} */

.notes-list-container__place-holder {
    color: gray;
}