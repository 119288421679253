.list-items {
    display: flex;
    flex-direction: column;
    overflow: none;
}

.no-search-results {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
}

.no-search-results h2 {
    padding: 0;
    margin: 0;
}

.no-search-results p {
    font-size: 0.8rem;
    color: gray;
}