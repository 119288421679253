.main {
  width: 100%;
  min-width: 300px;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.main::-webkit-scrollbar {
  display: none;
}

.header {
  display: flex;
  align-items: center;
  padding: 1rem 1rem 0.5rem 1rem;
}

.title {
  font-weight: bold;
  flex: 2;
}

.menu_seperator {
  margin: 0.5rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.content {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  font-size: 0.85rem;
  padding: 1rem 1rem 0.5rem 1rem;
}

.section {
  margin-bottom: 2rem;
}

.title {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-weight: bold;
}

.themebox {
  background: #2a2a2a;
  border: 1px solid #e3e3e3;
  width: 125px;
  height: 90px;
  padding: 10px;
  border-radius: 3px;
  position: relative;
  transition: 0.1s;
  border-radius: 0.25rem;
}

.themebox::before {
  content: "";
  position: absolute;
  background: var(--text-color-red);
  height: 30px;
  width: 100%;
  top: 0;
  left: 0;
}

.themebox h4 {
  position: relative;
  font-size: 13px;
  font-weight: 500;
  color: var(--text-color-red);
  top: 15px;
  font-weight: bold;
}

.toggle {
  width: 35px;
  padding: 2px;
  background-color: var(--DarkGray-color);
  display: block;
  border-radius: 1000px;
  cursor: pointer;
  transition: background-color 0.2s ease-in;
  position: absolute;
  top: 6px;
}

.toggleInner {
  width: 15px;
  height: 15px;
  background-color: gray;
  border-radius: 50%;
  transition: margin-left 0.2s ease-in, background-color 0.2s ease-in;
}

.toggleActive {
  margin-left: 20px;
}

.themebox h4 {
  margin: 1rem 0;
}
