.contact-modal {
  height: inherit;
}

.contact-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 0.5rem 1rem;
}

.prev,
.next {
  padding: 0.5rem;
  border-radius: 0.2rem;
  transition: 200ms all;
}

.prev:hover,
.next:hover {
  background-color: rgba(228, 228, 228, 0.481);
  cursor: pointer;
}

.contact-modal .breadcrumbs {
  display: flex;
  gap: 1rem;
  font-size: 0.9rem;
  color: rgb(68, 68, 68);
  height: 2rem;
}

.details-and-aside {
  display: flex;
  height: calc(100% - 3rem);
  /* 0ffset by header */
}

.contacts-details,
.contact__notes {
  padding: 2rem 2rem;
}

.auto-dial-notification,
.disposition-notification {
  display: flex;
  align-items: center;
  color: #721c24;
  background-color: #f8d7da;
  padding: 0rem 1rem;
  box-sizing: border-box;
  width: 100%;
  gap: 0.5rem;
  padding: 0.5rem 2rem;
  font-size: 0.8rem;
  position: relative;
}

.auto-dial-notification > div,
.disposition-notification > div {
  flex: 1;
}

.auto-dial-notification > button,
.disposition-notification > button {
  border: none;
  border-radius: 0.3rem;
  background-color: #721c24;
  color: #f8d7da;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem;
  width: 3.5rem;
  text-align: center;
  cursor: pointer;
}

.autoDialPaused {
  background-color: #d4edda;
  color: #155724;
}

.autoDialPaused > button {
  background-color: #155724;
  color: #d4edda;
}

.disposition-notification {
  background-color: var(--MediumGray-color);
  color: #000;
}

.contact__notes {
  padding-bottom: 0;
  /* height: 100vh; */
}

.details {
  /* flex: 0 0 70%; */
  width: 100%;
  overflow-y: auto;
  margin-right: -15px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  /* flex: 0 0 70%; */
  width: 100%;
  overflow-y: auto;
}

.details::-webkit-scrollbar {
  display: none;
}

.header-actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.cityStateZip {
  display: flex;
  flex: 0 0 20px;
  padding: 0;
  margin: 0;
  gap: 0.2rem;
}

.error-message {
  color: salmon;
  margin-top: 0.5rem;
}

.status-bar-container {
  display: flex;
  margin: 0.5rem 0;
}

.aside {
  background-color: var(--LightGray-color);
  width: 100%;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow-y: auto;
  box-sizing: border-box;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  flex: 0 0 30%;
}

.aside::-webkit-scrollbar {
  display: none;
}

.aside-item {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.aside-title {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
}

.aside-value {
  margin: 0;
  padding: 0;
  font-size: 0.75rem;
  color: gray;
  font-weight: 400;
}

.aside-item p {
  line-height: 1.5rem;
}

.aside-item p:nth-child(2) {
  color: gray;
  font-size: 0.75rem;
}

.open-aside-arrow {
  display: flex;
  align-items: center;
  padding: 0.25rem;
  margin-top: 1rem;
  background-color: #3068ce;
  color: white;
  height: 3rem;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  margin: auto;
}

.open-aside-arrow:hover {
  cursor: pointer;
}

.scheduled-events {
  display: flex;
}

.deals {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.deal {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  border: 1px solid rgb(227, 226, 226);
  padding: 0.5rem;
  border-radius: 0.25rem;
  transition: all 100ms ease-in;
}

.deal:hover {
  cursor: pointer;
  background-color: rgb(218, 218, 218);
}

.deal:hover > .dealDescription {
  color: rgb(204, 86, 70);
}

.pipelineStage {
  display: flex;
  gap: 0.25rem;
  font-size: 0.65rem;
  color: gray;
}

.dealDescription {
  font-size: 0.75rem;
  font-weight: 500;
  color: gray;
  transition: all 100ms ease-in;
}

.popper {
  opacity: 1;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0.2rem;
  z-index: 999;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
}

.dispositionPopperElement {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: 0.3rem;
}

.dispositionPopperElement li {
  list-style: none;
  margin: 0;
  padding: 0.5rem;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.8rem;
  padding: 0.25rem;
  border-radius: 0.25rem;
}

.menuItem:hover {
  background-color: var(--MediumGray-color);
  overflow: hidden;
  cursor: pointer;
}

.menuSeparator {
  margin: 0.5rem 0rem;
  border-bottom: 1px solid #ddd;
}

.horizontalLine {
  border-top: 0.5px solid rgba(214, 214, 214, 0.5);
}

.auxiliaries {
  display: flex;
  gap: 0.25rem;
  margin: auto 0px auto auto;
}

.autoDial,
.disposition {
  display: flex;
  align-items: center;
  color: #737373;
  border-radius: 0.25rem;
  box-sizing: content-box;
  position: relative;
  width: 1rem;
  height: 1rem;
}

.autoDial:hover,
.disposition:hover {
  cursor: pointer;
  background-color: var(--MediumGray-color);
}

.autoDial.active {
  background-color: var(--DarkGray-color);
}

.autoDial:hover p {
  display: block;
  transform-origin: 100% 0%;
  -webkit-animation: fadeIn 0.3s ease;
  animation: fadeIn 0.3s ease;
}

/* .disposition {
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  padding: 0.5rem;
  gap: 0.25rem;
}

.selectDisposition {
  flex: 2;
} */

.enter {
  animation: slideDown 300ms ease-in;
}

.enter-active {
  animation: slideDown 300ms ease-in;
}

.exit {
  animation: slideUp 300ms ease-in;
}

.exit-active {
  animation: slideUp 300ms ease-in;
}

@keyframes slideDown {
  0% {
    top: -100px;
  }

  100% {
    top: 0px;
  }
}

@keyframes slideUp {
  0% {
    top: 0px;
  }

  100% {
    top: -100px;
  }
}

.aside-enter {
  animation: openAside 500ms forwards;
}

.aside-exit {
  animation: closeAside 500ms forwards;
}

@keyframes openAside {
  0% {
    margin-right: -270px;
  }

  100% {
    margin-right: 0;
  }
}

@keyframes closeAside {
  0% {
    margin-right: 0;
  }

  100% {
    margin-right: -270px;
  }
}
