.cal-head {
  display: grid;
  grid-template-columns: 66px repeat(7, 1fr);
  width: 100%;
  justify-items: center;
  overflow: hidden;
  margin-bottom: 0.5rem;
  font-size: 0.8rem;
  align-items: center;
  height: 2rem;
}

.cal-body {
  height: calc(100vh - 205px);
  font-size: 0.8rem;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.time-table {
  display: flex;
}

.time-column {
  display: flex;
  flex-direction: column;
  width: 50px;
  justify-content: center;
  align-items: center;
  background-color: var(--MediumGray-color);
  border-top: 1px solid var(--MediumGray-color);
  /* margin-right: 0.25rem; */
  padding-left: 1rem;
}

.time-cells {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  font-size: 0.8rem;
  width: 100%;
}

.cal-body::-webkit-scrollbar {
  display: none;
}

.time-cells > div:nth-child(1) {
  border-left: none;
}

.active {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #3068ce;
  border-radius: 50%;
  height: 0.85rem;
  width: 0.85rem;
  padding: 0.7rem;
}

.cal-head-day {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}
