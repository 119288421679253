.checkbox > [type=checkbox]:not(:checked),
.checkbox > [type=checkbox]:checked {
    position: absolute;
    opacity: 0;
}

.checkbox > [type=checkbox]:not(:checked) + label,
.checkbox > [type=checkbox]:checked + label {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 2.5rem;
    font-size: 1.05em;
    line-height: 1.7;
    cursor: pointer;
}

.checkbox > [type=checkbox]:not(:checked) + label:before,
.checkbox > [type=checkbox]:checked + label:before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    border: none;
    border-radius: 1rem;
    height: 1rem;
    width: 1rem;
    background-color: rgba(240, 240, 240);
}

.checkbox > [type=checkbox]:not(:checked) + label:after, 
.checkbox > [type=checkbox]:checked + label:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 1rem;
    width: 1rem;
    border-radius: 1rem;
    background-color:rgb(0, 0, 0);
    -webkit-transition: all .2s;
    transition: all .2s;
}

.checkbox > [type=checkbox]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0) rotate(45deg);
    transform: scale(0) rotate(45deg);
}

.checkbox > [type=checkbox]:checked + label:after {
    opacity: .7;
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0);
}
/* 
    [type="checkbox"]:disabled:not(:checked) + label:before,
    [type="checkbox"]:disabled:checked + label:before {
        box-shadow: none;
        border-color: #bbb;
        background-color: #e9e9e9;
    }

    [type="checkbox"]:disabled:checked + label:after {
        color: #777;
    }

    [type="checkbox"]:disabled + label {
        color: #aaa;
    }

    [type="checkbox"]:checked:focus + label:before,
    [type="checkbox"]:not(:checked):focus + label:before {
        box-shadow: inset 0 1px 3px rgba(0,0,0, .1), 0 0 0 6px rgba(203, 34, 237, .2);
    }
     */