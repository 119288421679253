.header {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin: 0;
    padding: 0;
    background-color: #3068ce;
    /* background-color: rgb(36, 36, 36); */
    padding: 0.5rem 2rem;
    box-sizing: border-box;
    z-index: 12;
}

.navgation-menu {
    margin: 0;
    padding: 0;
}

.navigation-menu__icon {
    color: white;
}

.notification-bell {
    display: flex; 
    gap: 1.5em; 
    align-items: center;
}

.user-badge {
    display: flex;
    align-items: center;
    /* margin-top: .25rem; */
}

.navigation-menu ul {
    display: flex;
    align-items: center;
    list-style: none;
    text-decoration: none;
    margin: 0;
    padding: 0;
}

.navigation-menu li:nth-child(2) {
   justify-content: flex-start;
   flex: 2;
}

.right-icon-group {
    display: flex;
    gap: 1.5rem;
}

.right-icon-group:hover {
    cursor: pointer;
}

.navigation-menu__search {
    display: inline-block;
    font-size: 0.8rem;
    border-radius: 0.2rem;
    border-style: solid;
    border: none;
    padding: 0.5em 1em;
    transition: width 0.24s ease-in-out;
    -webkit-transition: width 0.4s ease-in-out;
    width: 150px;
    background-color: rgba(100, 100, 100, 0.8);
    margin: 0 1rem;
}

.navigation-menu__search:placeholder-shown {
        color: #ccc;
        font-style: italic; 
}

.navigation-menu__search:focus {
    width: 20%;
}

.navigation-menu__search:hover {
    width: 20%;
    background-color: white;
}

.side-drawer__menu-btn {
    width: 2rem;
    height: 1.25rem;
    background: transparent;
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-right: 2rem;
    cursor: pointer;
}

.side-drawer__menu-btn span {
    display: block;
    width: 1.25rem;
    height: 1px;
    background: rgb(255, 255, 255);
}

.popper {
    opacity: 1;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 0.2rem;
    z-index: 999;
    box-shadow: 1px 1px 15px rgba(0,0,0, 0.1);
    min-width: 275px;
}

.user-menu__item, .notifications-menu__item {
    padding: 0.3em;
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.8)
}

.settings-header, .user-menu__item__logout {
    display: flex;
    gap: 1rem;
    padding: 1rem;
}

.settings-header__container {
        display: flex; 
        flex-direction: column; 
        gap: 1rem
}

.settings-header__container__upper {
        display: flex; 
        flex-direction: row; 
        gap: 1rem
}

.settings-header__container__lower, .user-menu__item__logout {
    display: flex;
    gap: 0.5rem;
}

.settings-header:hover, .user-menu__item__logout:hover {
    background-color: var(--MediumGray-color);
    cursor: pointer;
    border-radius: 0.2rem;
}

.settings-header p {
    margin: 0;
    padding: 0;
    font-size: 0.85rem;
    line-height: 1.3rem;
}

.user-name {
    font-weight: bold;
}

.user-email {

}

.menu_separator {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06)
}  

.logout {
    cursor: pointer;
}

.notifications-menu {
    width: 550px;
}

.notifications-header {
    display: flex;
    gap: 1rem;
    padding: 1rem;
}

.notification-count {
    display: flex;
    justify-content: center;
    alignitems: center;
    padding: 2px 6px 3px;
    background-color: rgba(234, 83, 58);
    border-radius: 12px;
    color: white;
    font-size: 10px;
    position: absolute;
    margin: -11px 7px;
    z-index: 999;
    font-weight: bold;
    letter-spacing: -0.009rem;
}

.notifications-header {
    padding-bottom: 0.25rem;
}

.notification-type {
    display: flex;
    justify-content: space-around;
    gap: 1.5rem;
    font-size: 0.75rem;
    align-items: center;
    flex: 2;
    cursor: pointer;
    color: rgb(143, 143, 143);
}

.unread-count {
    color: rgb(100, 100, 100);
    background-color: rgb(232, 229, 229);
    padding: 0.1em 0.4rem 0.1rem;
    border-radius: 0.35rem;
    font-weight: 500;
    font-size: 0.7rem;
}

.unread-count.active {
    animation: blink 0.5s ease-in 0.1s;
}

@keyframes blink {
    0% {background-color: rgb(165, 227, 165)}
    100% {background-color: rgb(232, 229, 229); transform: translateY(0)}
}

.current-tab {
    position: relative;
    color: black;
}

.current-tab > span {
    background: rgba(0, 0, 0, .1)
}

.current-tab::before {
    content: "";
    position: absolute;
    bottom: 0;
    margin-bottom: -10px;
    left: 0;
    width: 100%;
    border-bottom: 1px solid rgb(177, 177, 177);
    transform: translateX(0);
    transition: transform 2s;
}

