.pipelineBoard {
  display: flex;
  overflow: auto;
  position: relative;
  box-sizing: border-box;
  height: 100%;
}

.stage {
  display: flex;
  flex-direction: column;
  gap: .75rem;
  min-width: 240px;
  position: relative;
  padding: .25rem;
  box-sizing: border-box;
  height: fit-content;
}

.actions {
  display: flex;
  gap: .25rem;
}

.addStage {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: .25rem;
  padding-left: .5rem;
  border-radius: .25rem;
  background-color: rgba(0, 0, 0, 0.03);
  color: gray;
}

.addStage:hover {
  cursor: pointer;
  color: #d1453b;
}

.addStage p {
  margin: 0;
  padding: 0;
}
