.cal-head {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    width: 100%;
    justify-items: center;
    overflow: hidden;
    margin-bottom: 1rem;
    font-size: .8rem;
}

.cal-body {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-auto-rows: 0;
    /* overflow-y: hidden; */
    height: calc(100vh - 205px)
}