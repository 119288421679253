.message-container {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
}

.message-container:first-child {
    margin-top: 2rem;
}

.message {
    display: flex;
    margin-bottom: 0.5rem;
    font-size: .8rem;
    width: fit-content;
    padding: 0.3rem 0.6rem;
    line-height: 1.2rem;
    color: rgb(20, 20, 20);
    color: white;
}

.date {
    color: gray; 
    font-size: .7rem;
    text-align: right;
}