.dark-theme {
  color: var(--text-color) !important;
  background-color: var(--body-color);
  color-scheme: dark;
}

.dark-theme .CalendarIcon_calendar-icon__kAnO2 svg {
  color: var(--text-color-red);
}

.dark-theme main {
  background-color: var(--body-color);
}

.dark-theme .SideDrawer_side-drawer__UtP4B {
  background-color: var(--sidebar-color);
}

.dark-theme .side-drawer,
.dark-theme .ListBoxHeader_list-box__header__6S4WE {
  background-color: var(--body-color);
}

.dark-theme .SideDrawer_side-drawer__UtP4B a {
  color: var(--text-color);
}

.dark-theme .SideDrawer_side-drawer__UtP4B li:hover {
  background-color: var(--MediumGray-color);
}

.dark-theme .ListBoxHeader_search__oc1hp {
  background-color: var(--MediumGray-color);
  color: var(--text-color);
}

.dark-theme .ListBoxHeader_list-box__filters__add-filter__oB9Pt {
  background-color: var(--text-color-red);
  color: white;
}

.dark-theme .ListBoxHeader_list-box__filters__active__filter__1hCS0 {
  background-color: var(--MediumGray-color);
  color: var(--text-color);
}

.dark-theme .ListBoxHeader_resultsCount__Lwdtq {
  background-color: var(--text-color-red);
  color: white;
}

.dark-theme .ListBoxHeader_list-box__filters__filter__Z5MC1,
.dark-theme .ListBoxHeader_list-box__header__6S4WE input[type="text"] {
  background-color: #07070759;
  color: var(--text-color);
}

.dark-theme .ListBoxHeader_popper__h-dGp {
  background-color: var(--DarkGray-color);
}

.dark-theme .ListBoxHeading_popper__1Exwj {
  background-color: var(--DarkGray-color);
}

.dark-theme .ListBoxHeading_menu_item__Z2eFQ:hover {
  background-color: #1c1c1c;
}

.dark-theme .PlusIcon_label__1YNsp.PlusIcon_active__usgkC {
  color: var(--text-color);
}

.dark-theme .PlusIcon_container__Qt\+UQ.PlusIcon_active__usgkC {
  background-color: var(--text-color-red);
}

.dark-theme .MainNavigation_popper__yrP6p,
.dark-theme .ContactModal_popper__h7tBx,
.dark-theme .EventItem_popper__wkN8W,
.dark-theme .Day_popper__yvaN9,
.dark-theme .EventItem_popper__UUIj8,
.dark-theme .WeekCell_popper__XC\+Si,
.dark-theme .MonthCell_popper__CzPGp,
.dark-theme .EventItemWeek_popper__iF6cG,
.dark-theme .DayCell_popper__fGGU8,
.dark-theme .PipelineDeal_deal__QgkvX,
.dark-theme .Task_editTask__v67Tj,
.dark-theme .Task_popper__QjBkT,
.dark-theme .PipelineDeal_popper__HQ5ed,
.dark-theme .PipelineStage_popper__CVHzu,
.dark-theme .PipelineStage_deal__fSlxK,
.dark-theme .PipelineHeader_popper__xAzEQ,
.dark-theme .DealModal_addTask__9X5X-,
.dark-theme .NotesForm_uploading-files__-kg\+5,
.dark-theme .Note_downloadable-file__obG4A,
.dark-theme .ContactModal_deal__QKySF {
  background-color: var(--DarkGray-color);
  border: 1px solid var(--border-color);
}

.dark-theme .ContactModal_deal__QKySF:hover,
.dark-theme .MonthCell_isOver__R6ATV,
.dark-theme .WeekCell_isOver__H\+uBJ,
.dark-theme .DayCell_isOver__Mghc2 {
  background-color: var(--MediumGray-color);
}

.dark-theme .PipelineDeal_deal__QgkvX:hover {
  border: 1px solid rgb(120, 120, 120);
}

.dark-theme .Call_received__o76K7 {
  color: var(--text-color);
}

.dark-theme .MainNavigation_menu_separator__Oq9wY,
.dark-theme .ListBoxHeading_menu_separator__0Anjm,
.dark-theme .FieldMapItem_menu_seperator__ayLKb,
.dark-theme .CreateDealPopup_menuSeparator__jyTVm {
  border-bottom: 1px solid var(--MediumGray-color);
}

.dark-theme .Call_call__IKJiy {
  border-bottom: 1px solid var(--MediumGray-color);
}

.dark-theme p,
.dark-theme .MainNavigation_user-menu__item__TZSNF,
.dark-theme .MainNavigation_notifications-menu__item__RaQrf,
.dark-theme .MainNavigation_user-menu__item__TZSNF,
.dark-theme .MainNavigation_notifications-menu__item__RaQrf,
.dark-theme .MainNavigation_current-tab__eRKj5,
.dark-theme .EventSchedulerPopup_calendar-dropdown-inner__iFx3g,
.dark-theme .EventSchedulerPopup_start-time-dropdown-inner__vIuCp,
.dark-theme .EventSchedulerPopup_end-time-dropdown-inner__8AF7n,
.dark-theme .react-calendar__navigation__label__labelText,
.dark-theme .react-calendar__navigation__label__labelText--from,
.dark-theme .react-calendar__navigation__arrow,
.dark-theme .react-calendar__navigation__next-button,
.dark-theme .react-calendar__month-view__days,
.dark-theme .react-calendar__tile,
.dark-theme .EventSchedulerPopup_start-time-list__dUjud,
.dark-theme .EventSchedulerPopup_end-time-list__da2of,
.dark-theme .EventItemWeek_title__4RLTQ,
.dark-theme .ContactModal_dealDescription__C66F8 {
  color: var(--text-color);
}

.dark-theme .react-calendar__navigation__arrow:hover {
  color: black;
}

.dark-theme .EventSchedulerPopup_start-time-list__dUjud,
.dark-theme .EventSchedulerPopup_end-time-list__da2of {
  background-color: var(--body-color);
}

.dark-theme .EventSchedulerPopup_delete__mA4hM {
  color: var(--text-color-red);
}

.dark-theme .react-calendar__tile:disabled {
  color: var(--MediumGray-color);
}

.dark-theme .react-calendar__tile--active {
  color: black;
}

.dark-theme .Card_card__w8kGb {
  background-color: var(--body-color);
}

.dark-theme .Input_custom-input__oim10,
.dark-theme .SettingsModal_custom-input__S\+jXq,
.SettingsModal_custom-input__S\+jXq {
  background-color: #00000075;
  border-bottom: none;
}

.dark-theme .Form_form-inputs__FO45d select,
.dark-theme .FieldMapItem_form__control__v8WOu select,
.dark-theme .NotificationModal_form__control__3KjXt select,
.dark-theme .NotificationModal_customSelect__7DC9y select {
  background-color: #282828;
  color: var(--text-color);
  border: 1px solid var(--LightGray-color);
}

.dark-theme .NotificationModal_customSelect__7DC9y:after {
  color: var(--LightGray-color);
}

.dark-theme .Select_form__control__HcYmo.Select_invalid__XkvWi select {
  background: salmon;
  border: 1px solid red;
}

.dark-theme .Form_form__actions__R8trT button:disabled {
  background-color: #00000075;
}

.dark-theme .FileUploader_drop-area__9B2bI {
  background-color: #00000075;
  border: 2px dashed #565656;
}

.dark-theme .CSVImport_upload-from-url__input__VeXjX > input {
  background: none;
  color: var(--text-color);
}

.dark-theme .SpinnerButton_button__HvtFZ:disabled {
  background-color: #00000075;
}

.dark-theme .SettingsModal_section__QzpLr {
  background-color: transparent !important;
}

.dark-theme .CloseX_close__OZkjf:hover,
.dark-theme .ContactModal_prev__1vSZR:hover,
.dark-theme .ContactModal_next__qhkeW:hover {
  background-color: rgb(0 0 0 / 48%);
}

.dark-theme .ListBoxHeader_save-filters-button__D5WFD,
.dark-theme .ListBoxHeader_clear-filters-button__dIK\+r {
  background-color: #00000075;
  color: var(--text-color);
}

.dark-theme
  .ListBoxHeader_list-box__filters__filter__Z5MC1
  .dark-theme
  .ListBoxHeader_active__hQUOy {
  color: var(--body-color);
}

.dark-theme .Auth_container__47MI1 {
  background-color: var(--body-color);
}

.dark-theme .MainNavigation_header__MAuZZ {
  background-color: var(--DarkGray-color);
}

.dark-theme .Auth_form-container__heading__MO8r5 {
  color: var(--text-color);
}

.dark-theme .Auth_form-container__94-13 div {
  color: var(--text-color);
}

.dark-theme .Auth_google-button__fFF3L {
  background-color: #7e7e7e40;
  color: var(--text-color);
}

.dark-theme .Input_custom-input__oim10,
.dark-theme .EventSchedulerPopup_text-area-element__IA1PZ,
.dark-theme .SettingsModal_custom-input__S\+jXq,
.dark-theme .Input_custom-input__oim10 {
  background-color: var(--MediumGray-color);
  color: var(--text-color);
  border-radius: 0.25rem;
}

.dark-theme .Auth_login-mode__kWzU5 {
  color: var(--text-color);
}

.dark-theme .SpinnerButton_button--blue__E7z0y,
.dark-theme .Button_button--blue__fwzDA {
  background-color: var(--text-color-red);
}

.dark-theme .Auth_area__uYz0Q {
  background-color: var(--body-color);
}

.dark-theme .Auth_container__left__6GLVo {
  background-color: var(--body-color);
}

.dark-theme .ListBoxHeader_active__hQUOy {
  background-color: var(--text-color-red) !important;
}

.dark-theme .Input_form__control__eOW5p input:focus,
.dark-theme .EventSchedulerPopup_text-area-element__IA1PZ:focus,
.dark-theme .SettingsModal_form__control__mV2Ga input:focus {
  background-color: rgb(0 0 0 / 35%);
}

.dark-theme .EventSchedulerPopup_text-area-element__IA1PZ::placeholder {
  color: var(--text-color);
}

.dark-theme .ListBoxHeader_list-box__header__6S4WE {
  border-bottom: 1px solid var(--MediumGray-color);
}

.dark-theme .ListItems_no-search-results__K9i8W svg {
  fill: var(--text-color);
}

.dark-theme .ListItem_list-item__contact-details__name__CcIa0 {
  color: var(--text-color);
}

.dark-theme .ListItem_list-item__RSceq:hover {
  background-color: var(--DarkGray-color);
}

.dark-theme .ContactsDetails_address__U4Tj2 ul {
  color: var(--text-color);
  border-color: var(--text-color-red);
}

.dark-theme .ContactModal_autoDial__wNz2q,
.dark-theme .ContactModal_disposition__kfi26 {
  color: var(--text-color);
}

.dark-theme .ContactModal_autoDial__wNz2q:hover,
.dark-theme .ContactModal_disposition__kfi26:hover {
  background-color: rgb(0 0 0 / 48%);
}

.dark-theme .Dot_unassigned__iB3SL {
  background-color: white;
}

.dark-theme input,
.dark-theme textarea {
  color: var(--text-color);
}

.dark-theme .Textarea_formControl__vgITT textarea:focus {
  background-color: transparent;
}

.dark-theme input::placeholder,
.dark-theme textarea::placeholder {
  color: var(--text-color);
}

.dark-theme .WorkflowBoardColumn_descriptionInput__6VDAZ input::placeholder,
.dark-theme .WorkflowModal_descriptionInput__-s1Ai input::placeholder,
.dark-theme .WorkflowModal_taskDescriptionInput__zdyRE input::placeholder,
.dark-theme .WorkflowModal_descriptionInput__-s1Ai textarea::placeholder,
.dark-theme .WorkflowModal_taskDescriptionInput__zdyRE textarea::placeholder,
.dark-theme .PipelineStage_descriptionInput__-R7kR2 input::placeholder,
.dark-theme .DealModal_taskDescriptionInput__uWfCO textarea::placeholder,
.dark-theme .PipelineStage_descriptionInput__-R7kR input::placeholder,
.dark-theme .CreateDealPopup_description__T4at7 input::placeholder {
  color: gray;
}

.dark-theme .ThemeSettings_themebox__3FcOr {
  background: #2a2a2a;
  border: 1px solid #3c3c3c;
}

.dark-theme .ThemeSettings_themebox__3FcOr h4 {
  color: var(--text-color);
  content: "Dark Theme";
}

.dark-theme .CallCenter_answer-button__IWexF {
  background-color: var(--text-color-red);
}

.dark-theme .DialPad_dial-pad__WYk7D {
  background-color: var(--DarkGray-color);
  border: 1px solid var(--border-color);
}

.dark-theme .ContactModal_addtl-details__Jhc9B {
  background-color: var(--sidebar-color);
}

.dark-theme .ContactModal_contact-modal-header__6cZ3M,
.dark-theme .WorkflowModal_workflowModalHeader__6KXa4,
.dark-theme .DealModal_dealModalHeader__YyK17 {
  background-color: var(--DarkGray-color);
}

.dark-theme .Dot_disabled__9dCHz {
  background-color: var(--text-color);
}

.dark-theme .next-call-a,
.dark-theme .prev-call-a {
  stroke: var(--text-color);
}

.dark-theme .Dialer_call-control__iSfPf {
  color: white;
}

.dark-theme .Dialer_muted-button__-T-NU.Dialer_active__ZJq8w {
  color: var(--text-color-red);
}

.dark-theme .ContactModal_nextButton__nfc2u:hover,
.dark-theme .ContactModal_prevButton__FfwA1:hover {
  background-color: rgb(0 0 0 / 48%);
}

.dark-theme .HelpTip_help-tip__OeBCZ,
.dark-theme .dark-theme .HelpTip_help-tip__OeBCZ {
  color: var(--text-color);
  border-color: var(--text-color);
}

.dark-theme .HelpTip_help-tip__OeBCZ p {
  background-color: rgb(51, 51, 51);
}

.dark-theme .HelpTip_help-tip__OeBCZ p:before {
  border-bottom-color: rgb(51, 51, 51);
}

.dark-theme
  .ContactCommunications_contact-communications__menu__xEV4x
  > a.ContactCommunications_active__oFXqC,
.dark-theme
  .DealModal_contact-communications__menu__\+gev6
  > .DealModal_active__YbjOA {
  border-bottom: 1px solid var(--text-color-red);
  color: var(--text-color);
  font-weight: 500;
}

.dark-theme .Messages_messages__-3vWI,
.dark-theme .Notes_notes__KIiao {
  background-color: transparent;
}

.dark-theme .MessageForm_message-form__8NxZo {
  background-color: var(--body-color);
}

.dark-theme .NotesForm_notes-form-container__1shoK {
  background-color: var(--body-color);
}

.dark-theme .NotesForm_form__control__J1XTN textarea,
.dark-theme .MessagesBox_form__control__Z1hrR textarea,
.dark-theme .SettingsModal_form__control__mV2Ga textarea,
.dark-theme .MessageForm_form__control__LmNP9 textarea {
  caret-color: var(--text-color);
  color: var(--text-color);
  background-color: transparent;
}

.dark-theme .NotesForm_notes-form-container__1shoK {
  background-color: var(--body-color);
  border: 1px solid var(--MediumGray-color);
}

.dark-theme .Note_downloadable-file__obG4A > a {
  color: var(--text-color);
}

.dark-theme .Note_downloadable-file__obG4A:hover > a {
  color: var(--text-color);
  color: rgb(207, 66, 47);
}

.dark-theme .paper-clip-svg {
  fill: none;
  stroke: white;
  stroke-linecap: round;
  stroke-miterlimit: 10;
  stroke-width: 5px;
}

.dark-theme .ContactModal_menuSeparator__Kivzl,
.dark-theme .DealModal_menuSeparator__ECxmT {
  border-bottom: 1px solid var(--MediumGray-color);
}

.dark-theme .MessageForm_form-container__s6y2L {
  background-color: var(--body-color);
  border: 1px solid var(--MediumGray-color);
}

.dark-theme .MessageForm_message-button__ikGyp:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor: not-allowed;
}

.dark-theme .MessageForm_message-button__ikGyp {
  background-color: var(--text-color-red);
}

.dark-theme .MessageNotification_message__geqoN {
  background-color: var(--DarkGray-color);
}

.dark-theme .MessageNotification_message-source__mJq39,
.dark-theme .MessageNotification_time-received__mXweS {
  color: var(--text-color);
}

.dark-theme .Messages_expanded-message__ly0Ln {
  background-color: var(--body-color);
}

.dark-theme .Messages_expand-messages__HWqgn:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.dark-theme .Messages_expand-messages__HWqgn.Messages_active__p4byu {
  background-color: rgba(255, 255, 255, 0.1);
}

.dark-theme .MessageNotification_actions__RmYN- {
  background-color: var(--MediumGray-color);
  color: var(--text-color);
}

.dark-theme .cal1,
.dark-theme .cal2 {
  stroke: var(--text-color-red);
  stroke-width: 1.3;
  stroke-miterlimit: 10;
}

.dark-theme .CalendarIcon_appointment__HHGJA .cal1 {
  stroke: green;
}

.dark-theme .CalendarIcon_appointment__HHGJA .cal2,
.dark-theme .CalendarIcon_appointment__HHGJA .cal3 {
  fill: green;
}

.dark-theme .CalendarIcon_follow-up__sS3hM .cal1 {
  stroke: yellow;
}

.dark-theme .CalendarIcon_follow-up__sS3hM .cal2,
.dark-theme .CalendarIcon_follow-up__sS3hM .cal3 {
  fill: yellow;
}

.dark-theme .Call_sender-name__T2wFX {
  color: var(--text-color-red);
}

.CSVImport_unmatched-key-success__LJ-5c > p {
  color: #155724 !important;
}

.dark-theme .FieldMapItem_value__9wVlk {
  color: var(--text-color);
}

.dark-theme .FieldMapItem_popper__FX1oB {
  background-color: var(--DarkGray-color);
}

.dkar-theme .CSVImport_unmatched-key-warning__U6cOP > p {
  color: #fff !important;
}

.dark-theme .CSVImport_csv-import-modal__x-cD\+ header,
.dark-theme .NewContactModal_contact-modal__6qLDR header,
.dark-theme .SettingsModal_header__XCd0W,
.dark-theme .ContactModal_popper__h7tBx header {
  background-color: var(--DarkGray-color);
  border-bottom: 1px solid var(--MediumGray-color);
}

.dark-theme .CSVImport_download-template__IW\+mw:hover {
  color: var(--LightGray-color);
}

.dark-theme .Main_main__dMJ2B,
.dark-theme .Main_active__hV7Hp {
  background-color: var(--body-color);
}

.dark-theme .MainNavigation_current-tab__eRKj5 > span {
  background-color: var(--text-color-red);
  color: rgb(255, 255, 255);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.177);
}

.dark-theme .NotesBox_notes-form__bU8FK {
  background-color: var(--body-color);
}

.dark-theme .SpinnerButton_button--gray__ZlWFQ,
.dark-theme .SpinnerButton_button--white__O7y3v {
  color: var(--text-color);
}

.dark-theme .SpinnerButton_button--white__O7y3v:hover {
  color: black;
}

.dark-theme .mute > path {
  fill: white;
}

.dark-theme .MainNavigation_notification-count__1ufnR {
  background-color: var(--text-color-red);
  color: rgb(255, 255, 255);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.177);
}

.dark-theme
  .EventSchedulerPopup_event-types__Mo1U7
  > .EventSchedulerPopup_form-control__pjP3n
  > input[type="radio"]:checked
  ~ label,
.dark-theme .EventSchedulerPopup_time-picker-button__6GfiW {
  background-color: var(--text-color-red);
}

.dark-theme
  .EventSchedulerPopup_form-control__pjP3n
  > input[type="radio"]:checked
  ~ label {
  background-color: var(--contact-divider);
}

.dark-theme .EventSchedulerPopup_react-calendar__9AGhK {
  background-color: var(--body-color);
}

.dark-theme .EventSchedulerPopup_time-picker__CgRw0 {
  color: var(--text-color);
}

.dark-theme .MonthCell_day__e\+JS\+.MonthCell_active__Ob7iI,
.dark-theme .CalendarWeek_active__xS\+pr {
  background-color: var(--text-color-red);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.dark-theme .MonthCell_day-container__DDBm1,
.dark-theme .WeekCell_cell__Aj0q7,
.dark-theme .WeekCell_week-cell__ZvwGD,
.dark-theme .DayCell_day-cell__7mfnm {
  border-color: rgba(255, 255, 255, 0.1);
}

.dark-theme .MonthCell_day-container__DDBm1.MonthCell_active__Ob7iI {
  background-color: rgba(0, 0, 0, 0.2);
}

.dark-theme .Day_day-container__lApe\+.Day_disabled__iDlua > .Day_day__LEmBE {
  color: rgba(255, 255, 255, 0.2);
}

.dark-theme .MonthCell_day-container__DDBm1.MonthCell_disabled__w\+LoX,
.dark-theme .WeekCell_week-cell__ZvwGD.WeekCell_disabled__qoG2R {
  background-color: rgba(0, 0, 0, 0.2);
}

.dark-theme .MonthCell_day-container__DDBm1.MonthCell_isOver__R6ATV {
  background-color: var(--MediumGray-color);
}

.dark-theme .EventSchedulerPopup_header__3\+Dwo,
.dark-theme .EventSchedulerPopup_menu-separator__H50a4,
.dark-theme .PhoneSettings_menu_seperator__HGgVi {
  border-bottom: 1px solid var(--MediumGray-color);
}

/* .dark-theme .PhoneSettings_section__mtU82 {
    background-color: var(--MediumGray-color);
} */

.dark-theme .EventSchedulerPopup_menu__HALPl input[type="search"],
.dark-theme .EventSchedulerPopup_menu__HALPl input[type="text"],
.dark-theme .PipelineStage_contactNameInput__zUtU\+ input,
.dark-theme .PipelineStage_descriptionInput__-R7kR input,
.dark-theme .CreateDealPopup_description__T4at7 input {
  background-color: transparent;
}

.dark-theme
  .PipelineStage_stage__AaVeA:has(.PipelineStage_header__0-\+vF:hover),
.dark-theme .PipelineStage_isDragging__Jzj21 {
  border: 2px solid var(--MediumGray-color);
  box-shadow: 0px 0px 5px var(--DarkGray-color);
}

.dark-theme
  .EventSchedulerPopup_form-control__pjP3n.EventSchedulerPopup_invalid__Me-sp
  > input[type="search"],
.dark-theme
  .EventSchedulerPopup_form-control__pjP3n.EventSchedulerPopup_invalid__Me-sp
  > input[type="text"] {
  background-color: rgb(250, 128, 114, 0.5);
  border-bottom: 1px solid red;
  padding: 0.7rem;
}

.dark-theme .EventSchedulerPopup_filtered-contacts-list__6r7Qk,
.dark-theme .WorkflowBoardColumn_filteredContactsList__LQ7Ny,
.dark-theme .PipelineStage_filteredContactsList__dbp-a,
.dark-theme .PipelineDeal_stageList__tklsF {
  background-color: var(--body-color);
  border: 1px solid var(--MediumGray-color);
  border-top: 1px solid var(--text-color-red);
}

.dark-theme .ContactModal_pipelineStage__8qZKe {
  color: var(--text-color);
}

.dark-theme .ContactModal_dealDescription__C66F8 {
  color: gray;
}

.dark-theme .PipelineStage_addNewContact__kGws4 span {
  color: var(--text-color-red);
}

.dark-theme .EventItem_title__KsMWy {
  color: var(--text-color);
}

.dark-theme .EventItem_event-item__sTOGb {
  background-color: rgba(50, 50, 50, 0.5);
}

.dark-theme .EventItemWeek_event-item__0Dpxq {
  background-color: rgba(50, 50, 50, 0.5);
}

.dark-theme .CalendarWeek_time-column__75xRS,
.dark-theme .CalendarDay_time-column__SsQMh {
  background-color: rgb(42, 42, 42);
  border-top: 1px solid var(--body-color);
}

.dark-theme .Note_container__qKCuf {
  background-color: var(--body-color);
}

.dark-theme .Note_form__control__74WFj textarea {
  background-color: var(--body-color);
  color: white;
}

.dark-theme .SettingsModal_phone-number__nF-h3 {
  background-color: var(--MediumGray-color);
}

.dark-theme .SettingsModal_phone-number__nF-h3:hover {
  background-color: var(--text-color-red);
}

.dkar-theme .PhoneSettings_phone-number__xbd27 {
  box-shadow: 1px 1px 2px rgb(0 0 0 / 30%);
  border: 1px solid rgb(255 255 255 / 20%);
}

.dark-theme .ViewMode_view-mode__-hoxd,
.dark-theme .CalendarBoxHeader_next-today-prev__qOqEh > div:nth-child(2),
.dark-theme .CalendarBoxHeader_next-today-prev__qOqEh > div {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-theme .ViewMode_horizontal-line__9r0VH,
.dark-theme .PipelineStage_horizontalLine__1NXdW,
.dark-theme .ContactModal_horizontalLine__\+C4I6 {
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-theme .ContactModal_open-aside-arrow__9Nkcc,
.dark-theme .Layout_open-aside-arrow__gp421,
.dark-theme .WorkflowModal_openAsideArrow__dKJMW,
.dark-theme .DealModal_openAsideArrow__IqBs4 {
  background-color: var(--text-color-red);
}

.dark-theme .ContactModal_aside__QIVix {
  background-color: var(--sidebar-color);
}

.dark-theme .ContactModal_disposition-notification__OJLn9 {
  color: white;
}

.dark-theme .CommunicationSideDrawer_communicationSideDrawer__uNHAz {
  background-color: var(--body-color);
}

.dark-theme .CommunicationSideDrawer_contact__PEk9E {
  border-bottom: 1px solid var(--MediumGray-color);
}

.dark-theme .CommunicationSideDrawerMessages_left__kWNDH {
  border-left: 1px solid var(--MediumGray-color);
  border-right: 1px solid var(--MediumGray-color);
}

.dark-theme .CommunicationSideDrawer_contacts__6pfub > div:first-child {
  border-top: 1px solid var(--MediumGray-color);
}

.dark-theme .CommunicationSideDrawerMessages_header__Z9E\+o {
  border-bottom: 1px solid var(--MediumGray-color);
  background-color: var(--DarkGray-color);
}

.dark-theme .CommunicationSideDrawerMessages_title__L-O9h {
  background-color: var(--DarkGray-color);
}

.dark-theme .CommunicationSideDrawer_noMessagesIcon__4J-MR,
.dark-theme .CommunicationSideDrawerMessages_noMessagesIcon__z87B9 {
  color: var(--text-color);
}

.dark-theme .PipelineDeal_kebabMenu__42At\+,
.dark-theme .PipelineStage_kebab__1Plaz {
  color: gray;
}

.dark-theme .WorkflowBoard_addColumn__RaiEY,
.dark-theme .PipelineBoard_addStage__tJD2C {
  background-color: rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .dark-theme .Auth_container__right__msDki {
    background-color: var(--body-color);
  }
}
