.createDeal {
  padding: 0.75rem;
}

.menu {
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.menuItem {
  font-size: 0.8rem;
}

.dealTitle {
  font-size: .9rem;
  font-weight: 500;
}

.menuSeparator {
  border-bottom: 1px solid #ddd;
}

.contactAndDescription {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}

.contactName {
  display: flex;
  gap: .25rem;
  align-items: center;
}

.description input {
  font-size: 0.7rem !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.description input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.description input[type="text"] {
  font: inherit;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  border: none;
  border-radius: 0.25rem;
}

.description input:focus {
  outline: none !important;
  border-radius: 0.25rem;
}

.formControl.invalid input[type="text"] {
  background-color: rgb(250, 128, 114, 0.5);
  border-bottom: 1px solid red;
  padding: 0.7rem;
}

.actions {
  display: flex;
  gap: 0.25rem;
}

.actions.dealActions {
  display: flex;
  justify-content: flex-end;
}

.createStage, .createPipeline {
  display: flex;
  flex-direction: column;
  gap: .5rem;
}

.stageError, .pipelineError {
  color: var(--text-color-red);
}
/* .createDeal {
  padding: 0.75rem;

}

.dealTitle {
  font-size: 1rem;
  font-weight: 500;
}

.dealContact {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}

.addedContact {
  display: flex;
  gap: 0.25rem;
  align-items: center;
  font-size: 0.8rem;
}

.contactDetails {
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
}

.dealDescription {
  font-size: 0.85rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.dealDescription {
  font-size: 0.7rem;
}

.dealDescription input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.dealDescription input[type="text"] {
  font: inherit;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  border: none;
  border-radius: 0.25rem;
}

.dealDescription input:focus {
  outline: none !important;
  border-radius: 0.25rem;
}

.form-control.invalid input[type="text"] {
  background-color: rgb(250, 128, 114, 0.5);
  border-bottom: 1px solid red;
  padding: 0.7rem;
}

.actions {
  display: flex;
  gap: 0.25rem;
}

.actions.dealActions {
  display: flex;
  justify-content: flex-end;
  padding: 0rem 0.75rem;
} */
