.user-badge {
    font-size: 0.9rem;
    border-radius: 50%;
    box-sizing: content-box;
    padding: 0.25rem;
    /* border: 1px solid white; */
    color: white;
}

.user-badge > span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
}

.user-badge:hover {
    cursor: pointer;
}

.x-small {
    width: 1rem;
    height: 1rem;
    font-size: 0.5rem;
}

.small, .default {
    width: 1.25rem;
    height: 1.25rem;
    font-size: .95rem;
    letter-spacing: -0.05rem;
}

.medium {
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem;
}

.large {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 2rem;
    letter-spacing: -0.2rem;
}

.firstInitial {
    margin: 0;
}

.lastInitial {
    margin: 0;
}

.purple {
    background-color: rgb(147, 17, 240);
    color: white;
    border: none;
}