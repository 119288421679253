.container:last-child > div {
  border: none;
}

.call {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.details {
  display: flex;
  gap: 0.5rem;
}

.sender-details {
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
  flex: 2;
}

.sender-name {
  font-weight: 500; 
  font-size: 0.9rem;
  color: #C25950;
}

.sender-number, .received { 
  display: flex;
  gap: 0.2rem;
  font-size: 0.75rem;
  color: rgba(0, 0, 0, 0.6);
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  width: 1.8rem;
  height: 1.8rem;
  padding: 0;
}

.button:hover {
  cursor: pointer;
}

.button:focus {
  outline: none;
  box-shadow: none;
}

.call-control:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.answer-button {
  background-color: rgb(76, 232, 92);
  transform: rotate(240deg);
  padding: 0.5rem;
  border: none;
  filter: none;
}

.answer-button:hover {
  opacity: 0.8;
  background-color: rgb(76, 232, 92);
}

.hangup-button {
  background-color: rgb(251, 107, 107);
  border: none;
}

.hangup-button:hover {
  opacity: 0.8;
  background-color: rgb(251, 107, 107);
}

.controls { 
  display: flex;
  gap: 1rem;
  align-items: center;
}

.mark-as-listened > input {
  background-color: var(--MediumGray-color);
}