.form__control {
  border: black;
  font-size: 0.85rem;
}

.form__control label {
  display: block;
  padding: 0.5rem;
}

.form__control input:focus {
  background-color: rgba(107, 193, 250, 0.5);
  outline: none !important;
}

.form__control.invalid input {
  border: salmon;
  border: 1px solid red;
}

.form__control.invalid label {
  color: red;
}

.form__control.invalid select {
  background: salmon;
  border: 1px solid red;
}

.form__control select {
  padding: 0.5rem;
  border-radius: 0.25rem;
  width: 100%;
}
