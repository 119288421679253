.addButton {
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.addButton:hover {
    cursor: pointer;
}

.container {
    box-sizing: border-box;
    border-radius: 50%;
    width: 18px;
    height: 18px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.container.active {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #d1453b;
}

.label.active {
    cursor: pointer;
    color: #d1453b;
}

.label {
    font-size: .8rem;
}







