.textarea {
    width: 100%;
    background-color: transparent;
    border: none;
    resize: none;
    font-family: inherit;
    box-sizing: border-box;
    border-radius: 0.5rem;
    overflow: hidden;
}

.border {
    border: 1px solid gray;
    padding: 0.5rem;
}

.formControl .textarea:focus {
    outline: none !important;
}

.formControl.invalid {
    background-color: rgb(250, 128, 114, 0.5);
    border-bottom: 1px solid red;
    border: 1px solid red
}