.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 998;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
}

.communicationSideDrawer {
  display: flex;
  background-color: white;
  height: 95vh;
  width: 900px;
  box-shadow: -5px -0px 10px rgba(0, 0, 0, 0.03);
  z-index: 999;
  border-radius: 0.5rem 0rem 0rem 0.5rem;
  position: fixed;
  right: 0;
}

.commBar {
  display: flex;
  flex-direction: column;
  gap: .5rem;
  font-size: 1.2rem;
  padding: .25rem;
}

.commBar a > div {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: .25rem;
  padding: .5rem;
}

.commBar a > div:hover {
  cursor: pointer;
  background-color: var(--MediumGray-color);
}

a.active > div {
  background-color: var(--MediumGray-color);
}

.commContainer {
  display: flex;
  flex: 1;
}

/* animation */

.backdropEnter {
  opacity: 0;
}

.backdropEnterActive {
  opacity: 1;
  transition: opacity 100ms linear;
}

.backdropExit {
  opacity: 1;
}

.backdropExitActive {
  opacity: 0;
  transition: opacity 300ms linear;
}

.commEnter {
  right: -900px;
}

.commEnterActive {
  right: 0;
  transition: right 100ms linear;
}

.commExit {
  right: 0;
}

.commExitActive {
  right: -900px;
  transition: right 100ms linear;
}

a:link {
  color: inherit;
}

a:visited {
  color: inherit;
}
