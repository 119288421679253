.notification {
    display: flex;
    border-radius: 0.5rem;
    position: fixed;
    z-index: 999;
    top: 3.5rem;
    right: 0.5rem;
    width: 350px;
    background-color: rgba(255, 255, 255);
    padding: 1rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
    font-size: 0.8rem;
}

.notification h3 {
    font-weight: 500;
    margin: 0rem 0 0.5rem 0;
    font-size: 0.9rem;
}

.link:hover {
    cursor: pointer;
}

.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1rem;
    height: 1rem;
    width: 1rem;
    border-radius: 50%;
}

.icon.success {
    border: 2px solid #155724;
}

.icon.error {
    border: 2px solid #721c24;
}

.content {
    flex: 2;
}

.content p {
    margin: 0;
    line-height: 1.2rem;
}

.close {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1rem;
    width: 1rem;
    border-radius: 0.2rem;
}
.close:hover {
    background-color: rgba(228, 228, 228, 0.314);
    cursor: pointer;
}

.primary {

}

.secondary {

}

.info {

}

.success {
    color: #155724;
    background-color: #d4edda;
}

.warning {
    color: #856404;
    background-color: #fff3cd;
}

.error {
    color: #721c24;
    background-color: #f8d7da;
}

.content.success p{
    color: #155724;
}

.content.warning p{
    color: #856404;
}

.content.error p {
    color: #721c24;
}
