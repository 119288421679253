.task {
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--MediumGray-color);
    padding: .75rem 0rem;
}

.nameAndDescription {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: .35rem;
}

.taskName {
    font-size: .85rem;
}

.taskDescription {
    font-size: .75rem;
    color: gray;
}

.task.completed {
    color: gray;
}

.task.completed .taskName, .task.completed .taskDescription  {
    text-decoration: line-through;
}

.popper {
    opacity: 1;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 0.2rem;
    z-index: 999;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
    
}

.deleteTask {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: .5rem 1rem;
}

.deleteTask p {
    padding: 0;
    margin: 0;
}

.deleteTask h5 {
    padding: 0;
    margin: 0;
    flex: 2;
}

.restoreTask:hover {
    cursor: pointer;
}

.trashCan {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: salmon;
    padding: .25rem;
    border-radius: .25rem;
}

.cancelIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(142, 142, 142);
    padding: .25rem;
    border-radius: .25rem;
}

.trashCan:hover, .cancelIcon:hover {
    cursor: pointer;
    opacity: .5;
    transition: all 500ms;
}

.editTask {
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(227, 227, 227);
    border-radius: .25rem;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, .05);
    margin-left: .25rem;
    box-sizing: border-box;
}

.taskDetails {
    display: flex;
    flex-direction: column;
    padding: .75rem;
}

.taskNameInput,
.descriptionInput,
.iconAndDesc,
.taskDescriptionInput {
    font-size: .85rem;
    color: gray;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.formControl textarea {
    width: 100%;
    border: none;
    font-family: inherit;
    resize: none;
    overflow: auto;
    overflow-y: hidden;
}

.formControl textarea:focus {
    outline: none;
}

.formControl .invalid input[type="text"] {
    background-color: rgb(250, 128, 114, 0.5);
    border-bottom: 1px solid red;
    padding: 0.7rem;
}

.horizontalLine {
    border-top: 0.5px solid rgba(214, 214, 214, .5);
}

.actions {
    display: flex;
    gap: .5rem;
}

.newTaskActions,
.descriptionActions {
    display: flex;
    justify-content: flex-end;
    padding: 0rem 0.75rem;
}
