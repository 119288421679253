.notification-modal {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1.5rem 1.5rem;
}

.header {
    padding: 1rem 1rem;
}

.title > h3 {
    margin: 0;
    padding: 0;

}

.close-modal-button {
    display: flex;
    justify-content: flex-end;
}

.footer {
    display: flex;
    align-items: center;
}

.learn-more {
    flex: 2;
    font-size: 0.75rem;
}

.actions {
    display: flex;
    gap: .5rem;
}

.customSelect {
    position: relative;
    width: 33%;
}

.customSelect select {
    -webkit-appearance: none;
    appearance: none;
    padding: 0.5rem;
    border-radius: 0.25rem;
    width: 100%;
}

.customSelect::after {
  font-size: .5rem;
  position: absolute;
  content: "▼";
  right: .75rem;
  pointer-events: none;
  top: 33%;
  color: black;
}

.customSelect.invalid select {
    background: salmon;
    border: 1px solid red;
}

