body {
  padding: 0;
  margin: 0;
  /* background: #202020; */
  /* color: white; */
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
}

* {
  scrollbar-width: none;
}

*::-webkit-scrollbar {
  display: none;
}

.home-page {
  background-color: #202020;
  color: white;
}

/* container */

.container {
  width: 80%;
  max-width: 100%;
  margin: 0 auto;
  overflow: hidden;
}

main {
  overflow-y: scroll;
}
@media (max-width: 768px) {
  .container {
    width: 90%;
  }
}

@media (max-width: 480px) {
  .container {
    width: 100%;
  }
}

.beta-banner {
  background: #282828;
  color: white;
}
.beta-banner .text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 30px;
  text-align: center;
}
.beta-banner a {
  color: #7ce98c;
}

@media (max-width: 768px) {
  .beta-banner .text {
    font-size: 11px;
  }
}

/* header */

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navbar-collapse.collapse.show {
  background-color: #282828;
}
.nav-logo {
  width: 72px;
  height: 37px;
}
.auth-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.auth-link {
  font-family: "Inter", sans-serif;
  font-size: 12px;
  font-weight: 300;
  line-height: 40px;
  text-align: center;
  color: white;
  text-decoration: none;
  padding: 0 10px;
}

.divider {
  font-size: 12px;
  font-weight: 300;
  line-height: 40px;
  color: white;
}

/* hero section */

.hero {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 75vh;
}

.hero .hero-title {
  font-family: Exo;
  font-size: 68px;
  font-weight: 500;
  line-height: 58px;
  text-align: center;
  color: white;
}
.hero .hero-subtitle {
  font-family: Inter;
  font-size: 19px;
  font-weight: 300;
  line-height: 28px;
  text-align: center;
  color: #ebebeb;
  margin-top: 10px;
}

.hero .get-started {
  display: flex;
  flex-direction: column;
  gap: 10px;
  text-align: center;
  margin-top: 5rem;
}

.hero .get-started .hero-button {
  width: 191.86px;
  height: 50px;
  border-radius: 50px;
  border: 1px;
  background: #7ce98c;
  color: #333333;
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
}
.hero .get-started .advisors-text {
  font-family: Inter;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  text-align: center;
}

@media (max-width: 768px) {
  .hero .hero-title {
    font-size: 39px;
    line-height: 40px;
  }
  .hero .hero-subtitle {
    font-size: 14px;
    line-height: 21px;
    width: 333px;
    margin: auto;
    margin-top: 5px;
  }
  .hero .get-started .advisors-text {
    font-size: 11px;
  }
  .hero .get-started {
    margin-top: 2rem;
  }
  .hero {
    height: 55vh;
  }
  .nav-logo {
    width: 52px;
    height: 27px;
  }
}

/* stats and chart section  */

.stats {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-center: center;
  height: 97.99px;
  background: #7ce98c;
}
.stats .stats-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin: auto;
}
.stats .stat-item .stat-number {
  font-family: Exo;
  font-size: 24px;
  font-weight: 700;
  line-height: 21px;
  color: #333333;
  text-align: center;
}
.stats .stat-item .stat-label {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  color: #333333;
  text-align: center;
}

@media (max-width: 768px) {
  .stats .stat-item .stat-number {
    font-size: 16px;
    line-height: 21px;
  }
  .stats .stat-item .stat-label {
    font-size: 10px;
    line-height: 12px;
  }
  .stats .stats-content {
    width: 100%;
  }
}

/* chart section */

.chart {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.chart .chart-heading {
  font-family: Exo;
  font-size: 60px;
  font-weight: 500;
  line-height: 60px;
}
.chart .subtitle {
  font-family: Inter;
  font-size: 19px;
  font-weight: 300;
  line-height: 28px;
  color: #ebebeb;
}

.chart .items-main {
  position: relative;
  margin-top: 2rem;
}

.chart .items-main::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 8px;
  background: linear-gradient(180deg, #e8898a 0%, #feea46 46.5%, #7ce98c 100%);
}

.chart .chart-items {
  color: white;
  margin-left: 1.5rem;
  margin-bottom: 1.5rem;
  max-width: 335px;
}

.chart .chart-items .bullet {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.chart .chart-items h3 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  margin: 0;
}
.chart .chart-items p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
}
.chart .icon-bullet {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 99px;
  margin-right: 5px;
}
.chart-wrapper p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  text-align: left;
}

.chart-wrapper .mobile-view {
  display: none;
}

.chart .manual {
  background-color: #e8898a;
}
.chart .auto {
  background-color: #feea46;
}
.chart .ai {
  background-color: #7ce98c;
}
.chart-img {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .chart .chart-heading {
    font-size: 32px;
    line-height: 21px;
    text-align: center;
  }
  .chart .subtitle {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    margin-top: 10px;
  }
  .chart .chart-items {
    display: none;
  }
  .chart-wrapper .mobile-view {
    display: block;
  }

  .chart-wrapper .items {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }
  .chart-wrapper .items-content {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

/* feature */

.feature {
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-color: #282828;
  clip-path: polygon(0% 0%, 100% 0%, 100% 92%, 50% 100%, 0 92%);
  height: fit-content;
}
.feature .feature-content {
  padding-top: 5rem;
  color: white;
}
.feature .feature-cards {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 15px;
  overflow: hidden;
}

.feature .feature-card {
  border-radius: 0px 10px 10px 0px;
  border: 5px;
  background: #202020;
  width: 100%;
  padding: 10px 10px 10px 20px;
  height: fit-content;
}

.feature .feature-heading {
  font-family: Exo;
  font-size: 60px;
  font-weight: 500;
  line-height: 60px;
  text-align: center;
}

.feature .feature-subTitle {
  font-family: Inter;
  font-size: 19px;
  font-weight: 300;
  line-height: 26px;
  text-align: center;
  width: 752.77px;
  margin: auto;
  color: #ebebeb;
}

.feature .feature-card .title-icon {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.feature .calling-card {
  border-left: 5px solid #7ce98c;
}
.feature .texting-card {
  border-left: 5px solid #feea46;
}
.feature .email-card {
  border-left: 5px solid #e8898a;
}
.feature .notes-card {
  border-left: 5px solid #919191;
}

.feature .feature-card h3 {
  font-family: Inter;
  font-size: 15px;
  font-weight: 700;
}
.feature .feature-card p {
  font-family: Inter;
  font-size: 12px;
  font-weight: 300;
  line-height: 17px;
  max-width: 250px;
}

.feature .icon-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 99px;
  width: 25.35px;
  height: 25.35px;
}
.feature .calling-icon {
  background: #7ce98c;
}
.feature .texting-icon {
  background: #feea46;
}
.feature .email-icon {
  background: #e8898a;
}
.feature .notes-icon {
  background: #919191;
}

.feature .icon-svg {
  width: 12px;
  height: 13px;
  background-image: url("../../assets/imgs/phone.svg");
  background-size: contain;
  background-repeat: no-repeat;
}

.feature .feature-img {
  max-width: 100%;
  max-height: auto;
  border-radius: 10px;
  background: #303030;
  padding: 20px;
}
.feature .feature-sm-img {
  display: none;
}
.feature .feature-img img {
  width: 100%;
  height: auto;
}

.feature .feature-rigt-content {
  display: flex;
  gap: 10px;
  align-items: center;
}

@media (max-width: 768px) {
  .feature .feature-heading {
    font-size: 32px;
    line-height: 44px;
  }

  .feature .feature-subTitle {
    font-size: 14px;
    line-height: 21px;
    width: 100%;
  }
  .feature .feature-img {
    padding: 7px;
    width: 377px;
    height: 306.33px;
    transform: translateX(10%);
    position: relative;
  }
  .feature .feature-img img {
    width: 100%;
    object-fit: cover;
    height: 100%;
  }
  .feature .feature-content {
    padding-top: 1rem;
  }
  .feature .feature-sm-img {
    display: block;
    border-radius: 11px;
    background: #303030;
    padding: 7px;
    width: 377px;
  }
  .feature .feature-sm-img img {
    width: 100%;
    height: 306.33px;
  }
}

/* workflows-section */

.workflows-section {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background: #282828;
  border-bottom: 4px solid;
  border-image-source: linear-gradient(
    90deg,
    #e8898a 0%,
    #feea46 52%,
    #7ce98c 100%
  );
  border-image-slice: 1;
}

.workflows-section .heading-content h2 {
  font-family: Exo;
  font-size: 60px;
  font-weight: 500;
  line-height: 60px;
  text-align: center;
  max-width: 842px;
  margin: auto;
}
.workflows-section .heading-content p {
  font-family: Inter;
  font-size: 19px;
  font-weight: 300;
  line-height: 26px;
  text-align: center;
  max-width: 528px;
  margin: auto;
  color: #ebebeb;
  margin-top: 5px;
}

.workflows-section .kanban-wrapper h4 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
}

.workflows-section .board {
  margin-top: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: start;
  gap: 20px;
  justify-content: space-between;
  overflow: auto;
  overflow-x: scroll;
}

.kanban-card {
  background: #202020;
  border-radius: 5px;
  width: 100%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: fit-content;
}
.kanban-card .kanban-header h5 {
  font-family: Inter;
  font-size: 14px;
  font-weight: 700;
  line-height: 21px;
  color: white;
}

.kanban-header {
  font-weight: bold;
  margin-bottom: 12px;
  font-size: 16px;
  color: #172b4d;
  position: relative;
}

.kanban-card .task-list .task {
  display: flex;
  align-items: center;
  gap: 10px;
}
.kanban-card .task-list .task h2 {
  font-family: Inter;
  font-size: 13px;
  font-weight: 500;
  line-height: 21px;
}
.kanban-card .task-list .task p {
  font-family: Inter;
  font-size: 9px;
  font-weight: 300;
  line-height: 21px;
  color: #919191;
}
.kanban-card .task-list .task .dot {
  width: 8px;
  height: 8px;
  background: #7ce98c;
  border-radius: 99px;
}

.kanban-card .task {
  background: #282828;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 8px;
}

.kanban-card .task:active {
  cursor: grabbing;
}

.kanban-card .task-input-container {
  margin-top: 15px;
}
.kanban-card .task-input {
  width: 100%;
  padding: 8px;
  font-size: 14px;
  background: none;
  border: none;
  outline: none;
  color: white;
}

.dragging {
  opacity: 0.5;
}

.kanban.dragover {
  background: #dfe1e6;
}

.kanban-card .task-input::placeholder {
  color: white;
  font-family: Inter;
  font-size: 11px;
  font-weight: 300;
  line-height: 21px;
}

@media (max-width: 768px) {
  .workflows-section .heading-content h2 {
    font-size: 32px;
    line-height: 44px;
    text-align: left;
  }
  .workflows-section .heading-content p {
    font-size: 14px;
    line-height: 21px;
    text-align: left;
  }
  .workflows-section .kanban-wrapper h4 {
    font-size: 12px;
    line-height: 21px;
    margin-top: 2rem;
  }
  .workflows-section .board {
    grid-template-columns: repeat(3, 80vw);
    gap: 10px;
  }
}

/* Book and Manage */

.manage-section {
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.manage-section .manage-img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  background: #303030;
  padding: 20px;
}
.manage-sectione .manage-img img {
  width: 723px;
  height: 517px;
}

.manage-section .manage-content {
  position: relative;
  padding-top: 1rem;
}
.manage-section .manage-content ::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 8px;
  background: linear-gradient(180deg, #e8898a 0%, #feea46 46.5%, #7ce98c 100%);
}
.manage-section .manage-heading {
  font-family: Exo;
  font-size: 60px;
  font-weight: 500;
  line-height: 21px;
  margin-left: 2rem;
}
.manage-section .manage-subTitle {
  font-family: Inter;
  font-size: 19px;
  font-weight: 300;
  line-height: 26px;
  width: 488px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-left: 2rem;
}

.manage-section .time-slots {
  width: 73px;
  background: #282828;
  overflow: hidden;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.manage-section .time-left-content .book-card-main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-top: 16px;
  padding-bottom: 16px;
}
.manage-section .time-slots .time {
  font-family: Inter;
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  text-align: center;
}

.manage-section .book-card {
  width: 264px;
  border: 0px 0px 0px 5px;
  background: #282828;
  height: 47px;
  padding-top: 5px;
}
.manage-section .yellow {
  border-left: 5px solid #feea46;
}
.manage-section .green {
  border-left: 5px solid #7ce98c;
}

.manage-section .book-card .dot {
  width: 11.8px;
  height: 12px;
  border-radius: 99px;
}

.manage-section .book-card .dot-green {
  background-color: #7ce98c;
}
.manage-section .book-card .dot-yellow {
  background-color: #feea46;
}
.manage-section .book-card .content {
  display: flex;
  align-items: center;
  margin-left: 20px;
  gap: 10px;
}

.manage-section .time-left-content {
  display: flex;
  item-align: center;
  gap: 2rem;
  height: 100%;
}
.manage-section .empty {
  height: 20px;
}

.manage-section .book-card h4 {
  font-family: Inter;
  font-size: 16px;
  font-weight: 300;
  line-height: 21px;
  margin-bottom: 0px;
}
.manage-section .book-card p {
  font-family: Inter;
  font-size: 10px;
  font-weight: 300;
  line-height: 21px;
}

@media (max-width: 768px) {
  .manage-section .manage-heading {
    font-size: 32px;
    line-height: 21px;
    text-align: center;
  }
  .manage-section .manage-subTitle {
    font-size: 14px;
    line-height: 21px;
    text-align: center;
    width: 100%;
    margin: 0;
    margin-top: 10px;
  }
  .manage-section .manage-content ::before {
    display: none;
  }
  .manage-section .manage-img {
    display: none;
  }
  .manage-section .time-left-content {
    margin-top: 2rem;
  }
  .manage-section .book-card h4 {
    font-size: 13px;
    line-height: 21px;
  }
  .manage-section .book-card p {
    font-size: 7px;
    line-height: 21px;
  }
}

/* footer */

.footer {
  color: white;
  padding: 5rem;
}

.footer-logo {
  margin-bottom: 1rem;
}
.footer-logo img {
  width: 72px;
  height: 37px;
}

.footer .logo-icons {
  display: flex;
  flex-direction: column;
}

.social-icons {
  display: flex;
  align-items: center;
  gap: 15px;
}

.footer h5 {
  font-size: 1.1rem;
  font-weight: bold;
  color: white;
  margin-bottom: 1.5rem;
}

.footer ul {
  padding-left: 0;
  list-style: none;
}

.footer ul li {
  margin-top: 10px;
}
.footer ul li a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
  font-size: 16px;
}

.footer ul li a:hover {
  color: #ffffff;
}

@media (max-width: 768px) {
  .footer {
    padding: 0;
    padding-top: 2rem;
  }
  .footer .logo-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .footer-logo {
    margin: 0;
  }
  .links {
    margin-top: 2rem;
  }
}

.chart-container {
  max-width: 100%;
  height: 800px;
  height: 0;
  padding-bottom: 66%;
  position: relative;
  margin: 0 auto;
}

canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
