.deal {
    display: flex;
    flex-direction: column;
    border: 1px solid rgb(227, 227, 227);
    border-radius: .25rem;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, .03);
    width: 245px;
    box-sizing: border-box;
    cursor: move;
    margin-bottom: .75rem;
    background-color: white;
    transition: border 200ms linear;
    transition: box-shadow 200ms linear
  }

  .deal:hover {
    cursor: pointer;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, .1);
    border: 1px solid rgb(206, 206, 206);
  }

  .dropRef {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  .contactDetails {
    display: flex;
    flex-direction: column;
    gap: .35rem;
    padding: .75rem;
  }

  .contactNameAndKebab {
    display: flex;
    align-items: center;
  }
  
  .contactName {
    font-size: .85rem;
    flex: 2;
  }
  
  .description {
    font-size: .7rem;
    color: gray;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .kebab {
    color: rgba(0, 0, 0, 0.5)
  }
  
  .kebabMenu {
    transform: rotate(90deg);
    height: 1rem;
  }
  
  .kebabMenu:hover {
    cursor: pointer;
  }

  .popper {
    opacity: 1;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 0.2rem;
    z-index: 999;
    box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
    min-width: 250px;
  }
  
  .editCardMenu {
    list-style: none;
    padding: 0;
    margin: 0;
    padding: .3rem;
  }
  
  .editCardMenu li {
    list-style: none;
    margin: 0;
    padding: .5rem;
  }
  
  .menuItem {
    display: flex;
    align-items: center;
    gap: .5rem;
    font-size: .8rem;
    padding: .25rem;
    border-radius: .25rem;
  }
  
  .menuItem:hover {
    background-color: var(--MediumGray-color);
    overflow: hidden;
    cursor: pointer;
  }
  
  ul.stageList {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    font-size: 0.8rem;
    /* background-color: var(--DarkGray-color); */
    padding: 0.5rem;
    border-top: 1px solid #3068ce;
    background-color: rgb(243, 243, 243);
    position: absolute;
    z-index: 999;
    right: 0;
    left: 0;
    box-sizing: border-box;
    margin: 0 15px;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    max-height: 250px;
    overflow: auto;
    overflow-x: hidden;
    box-shadow: 0px 3px 3px rgba(0, 0, 0, .05);
    max-height: 10rem;

  }
  
  ul.stageList > li {
    padding: .5rem .5rem;
    border-radius: .25rem;
  }
  
  .stageList > li:hover {
    background-color: var(--MediumGray-color);
    cursor: pointer;
  }

