.pipelineBox {
  margin: 0 auto;
  overflow: scroll;
  overflow-y: hidden;
  overflow-x: hidden;
  padding: 3rem 3rem 0rem 3rem;
  /* max-width: 960px; */
  height: 100vh;
}

.actions {
  display: flex;
  gap: .25rem;
}

