.contactsDetails {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.contactsDetails {
  padding: 2rem 2rem 0rem 2rem;
}

.fullName {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
}

.address ul {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 0.8rem;
  font-family: Arial, Helvetica, sans-serif;
  margin-top: 0.2rem;

  border-left: 1px solid #3068ce;
  padding-left: 0.75rem;
}

.address li,
.contacts-details__right li {
  margin: 0.5rem 0rem;
}

.address {
  display: flex;
}

.streetNumber, .cityStateZip, .phoneNumber, .emailAddress {
  display: flex;
  align-items: center;
  gap: .25rem;
  height: 1rem;
}

.streetNumber > input, .cityStateZip > input, .phoneNumber > input, .emailAddress > input {
  font: inherit;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
  font-size: 0.8rem;
  background-color: transparent;
}

.streetNumber > input:focus, .cityStateZip > input:focus, .phoneNumber > input:focus, .emailAddress > input:focus {
  outline: none !important;
}

.streetNumber.invalid input, .cityStateZip.invalid input, .phoneNumber.invalid input, .emailAddress.invalid input {
  border-bottom: 1px solid salmon;
}

.streetNumber:hover, .cityStateZip:hover, .phoneNumber:hover, .emailAddress:hover {
  cursor: pointer;
}

.streetNumber:hover .edit, .cityStateZip:hover .edit, .phoneNumber:hover .edit, .emailAddress:hover .edit {
  display: flex;
}


.error {
  color: salmon;
  margin-top: 0.5rem;
  display: block;
}

.edit {
  display: none;
  cursor: pointer;
}

.save:hover {
  color: rgb(3, 228, 3);
  cursor: pointer;
}

.cancel:hover {
  color: red;
  cursor: pointer;
}