.csv-import-modal header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    padding: 0.5rem 1rem;
}

.csv-import-modal .breadcrumbs {
    display: flex;
    gap: 1rem;
    font-size: .9rem;
    color: rgb(68, 68, 68);
    height: 2rem;
}

.csv-import {
    position: relative;
}

.csv-import:hover {
    cursor: pointer;
}

.csv-import > input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
    font-size: 0;
    cursor: pointer;
    width: 100%;
    height: 100%
}

.csv-import > input:hover {
    cursor: pointer;
}

.title {
    display: flex;
    align-items: center;
    flex: 2;
}

.title > h2 {
    margin: 0;
    padding: 0;
    flex: 2;
}

.number-of-contacts > p {
    color: gray;
}

.download-template > a {
    color: rgb(60, 189, 75);
}
.download-template > a:active {
    text-decoration: none;
}

.download-template {
    transition: 200ms ease-in;
    color: rgb(27 148 41);
}

.download-template > span {
    font-size: 0.8rem;
    color: rgb(170, 170, 170);
    transition: 200ms ease-in;
}

.download-template:hover {
    color: var(--DarkGray-color);
    cursor: pointer;
}

.download-template:hover > span {
    color: white;
}

.description {
    display: flex;
    color: gray;   
    align-items: center;
    gap: 0.25rem;
}

.drag-and-drop-files {
    display: flex;
    flex-direction: column;
    margin: 2.5rem 0;
    gap: 2.5rem;
    position: relative;
}

.formatting {
    font-size: 0.8rem;
    color: gray;
    text-align: center;   
    margin: 0.5rem 0;
}

.fields {
    margin: 1.5rem 0; 
    position: relative;
}

.strike-through-separator {
    position: relative;
    font-size: 0.8rem;
    z-index: 1;
    overflow: hidden;
    text-align: center;
    color: gray;   
    font-weight: bold;
 
}

.strike-through-separator::before, .strike-through-separator::after {
    position: absolute;
    top: 51%;
    background-color: rgb(236, 236, 236);
    height: 1px;
    width: 40%;
    content: "\a0";
    overflow: hidden;
}

.strike-through-separator:before {
    margin-left: -42%;
    text-align: right;
}

.strike-through-separator:after {
    margin-left: 2%;
}

.number-of-contacts > p, .description > p {
    padding: 0;
    margin: 0;
}

.upload-from-url {
    font-size: 0.8rem;
    color: gray;   
    font-weight: bold;
   
}

.upload-from-url__container {
    display: flex;
    align-items: center;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 0.5rem;
    padding: 0.25rem;
    position: relative;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.upload-from-url__container::before {
    position: absolute;
    border-right: 1px solid rgb(216, 216, 216);
    width: 10%;
    height: 100%;
    content: "http://";
    font-size: 0.9rem;
    color: gray;
    text-align: center;
    line-height: 2.5rem;
    font-weight: normal;
}

.upload-from-url__input {
    flex: 2;
    display: flex;
}

.upload-from-url__input > input  {
    margin-left: 5rem;
    height: 1.5rem;
    flex-basis: 100%;
    border: none;
    outline: none;
}

.menu_separator {
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}  

.support {
    display: flex; 
    gap: 0.25rem; align-items: center;
    flex: 2;
}

.support > p {
    font-weight: bold;
    color: gray;
}

.support > :first-child:hover {
    cursor: pointer;
}

.support > :nth-child(2) {
    cursor: pointer;
}

.actions {
    display: flex;
    gap: 0.5rem;
}

.unmatched-key-warning { 
    /* background-color: var(--text-color-red);  */
    padding: 0 0.5rem; 
    display: flex; 
    align-items: center; 
    gap: 0.5rem;
    transition: all 200ms;
    color: #fff;
    padding: .25rem;
    box-sizing: border-box;
}

.unmatched-key-warning > p, .unmatched-key-success > p {
    margin: 0;
    padding: 0;
}

.unmatched-key-success {
    background-color: #d4edda;
    color: #155724; 
    padding: 0 0.5rem; 
    display: flex; 
    align-items: center; 
    gap: 0.5rem;
    transition: all 200ms;
}

.strike-and-upload {
    display: flex; 
    flex-direction: column; 
    gap: 2rem; 
    margin: 2rem 0;
}
