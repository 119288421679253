.messages {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 14px;
    overflow-x: hidden;
    background-color: white;
    padding: 0rem 1.5rem;
    scroll-behavior: smooth;
    overflow-y: scroll;
    min-height: 150px;
    overflow-y: hidden;
}

.enter-message {
    color: gray;
}