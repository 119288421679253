.notes {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.notes-form {
    position: sticky;
    bottom: 0;
    padding-bottom: 1rem;
    background: #fff
}