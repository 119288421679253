.main {
  width: 100%;
  min-width: 300px;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.main::-webkit-scrollbar {
  display: none;
}

.header {
  display: flex;
  align-items: center;
  padding: 1rem 1rem 0.5rem 1rem;
}

.header-title {
  font-weight: bold;
  flex: 2;
}

.menu_seperator {
  margin: 0.5rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.content {
  font-size: 0.85rem;
  padding: 1rem 1rem 0.5rem 1rem;
}

.section {
  margin-bottom: 2rem;
}

.title {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-weight: bold;
}

.configureNumber {
  background-color: aliceblue;
  padding: 1rem;
  border-radius: 0.3rem;
}

.phone-numbers {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 350px;
  overflow: scroll;
  border: solid 1.5rem rgba(#000, 1);
  overflow-x: hidden;
  padding: 1rem;
}

.phone-number {
  border-radius: 0.3rem;
  padding: 1.2rem;
  text-align: center;
  box-shadow: 1px 1px 2px rgb(0 0 0 / 5%);
  border: 1px solid rgb(0 0 0 / 10%);
}

.phone-number:hover {
  background-color: var(--MediumGray-color);
  cursor: pointer;
}

.greeting {
  border-radius: 0.25rem;
  background-color: var(--MediumGray-color);
  padding: 0.5rem;
}

.options {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin: 1rem 0rem;
}

.add-option {
  margin: 1rem 0;
}

.phone-buttons,
.select-routes {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  width: 25%;
}

.add-option {
  display: flex;
  align-items: center;
}

.routes {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.route {
  display: flex;
  gap: 0.25rem;
  padding: 1rem;
  background-color: var(--MediumGray-color);
  border-radius: 0.25rem;
}

.route button {
  border-style: none;
  border-radius: 0.15rem;
  font-size: 0.85rem;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}
