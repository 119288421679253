.contact-modal {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    box-sizing: border-box;
    width: 100%;
    overflow: auto;
    overflow-x: hidden;
}

.ContactModal > .details {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    min-height: 0;
    
}

.contact-modal header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    padding: 0.5rem 1rem;
    
}

.contact-modal .breadcrumbs {
    display: flex;
    gap: 1rem;
    font-size: .9rem;
    color: rgb(68, 68, 68);
    height: 2rem;
}

.section-1 {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: baseline;
    box-sizing: border-box;
    padding: 1.5rem 2rem;
}

.section-1__modal-sub-title p{
    padding: 0;
    margin: 0;
    color: gray;   
}

.section-1__modal-title > h2 {
    margin: 0;
    padding: 0;
}

.section-2 {
    padding: 1.5rem 2rem;
}

