.dial-pad-button {
    position: fixed;
    bottom: 0;
    right: 1rem;
    padding: 1rem;
}

.call-button {
    all: unset;
    cursor: pointer;
}

.dialPad {
    padding: 1rem;
   
}

.call-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0.75rem;
    border-radius: 50%;
    background-color:rgba(0, 0, 0, 0.6);
    box-shadow: -3px 1px 5px rgba(0, 0, 0, 0.2);
}

.call-button:hover {
    opacity: .8;
}

.answer-button {
    background-color: #3068ce;
    transform: rotate(240deg);
}
