.button{
    border: none;
    padding: .8rem;
    border-radius: 0.5rem;
    background-color: rgb(0, 0, 0);
    transition: opacity .3s ease-in;
    color: white;
    display: inline-block;
    margin: 0.5rem 0rem;
    flex: 1;
} 

.button:hover {
    opacity: .8;
    cursor: pointer;
}

.button:disabled{
    background-color: lightgray;
    color: darkgray;
    text-shadow: none;
} 

.button:disabled:hover {
    opacity: 1;
    cursor: not-allowed;
}

.button.button--red {
    background-color: rgb(233, 136, 136);
}

.button.button--yellow {
    background-color: rgb(253, 243, 107);
}

.button.button--green {
    background-color: rgb(118, 234, 130);
}

.button--gray {
    background-color: var(--MediumGray-color);
    color: rgb(142, 142, 142);
}

.button.button--blue {
    background-color: #3068ce;
    color: white;
}

.button.button--large {
    width: 100%
}

.button.button--medium {
    width: 50%;
}

.button.button--small {
    width: 25%
}


