.settings-modal {
  display: flex;
  height: 100%;
}

.settings-modal p {
  margin: 0.75rem 0;
}

.aside {
  display: flex;
  flex-direction: column;
  flex: 0 0 25%;
  gap: 0.5rem;
  overflow-y: auto;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.05);
  height: 100%;
}

.title {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-weight: bold;
}

.settings {
  padding: 1rem 1rem 0.5rem 1rem;
}

.items {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: 0.85rem;
}

.items a:visited {
  text-decoration: none;
  color: inherit;
}

.items a {
  text-decoration: none;
  color: inherit;
}

.item {
  display: flex;
  gap: 0.6rem;
  padding: 0.5rem;
}

.itemLink.active {
  background: var(--MediumGray-color);
  cursor: pointer;
  border-radius: 0.2rem;
}

.main {
  width: 100%;
  min-width: 300px;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.main::-webkit-scrollbar {
  display: none;
}

.header {
  display: flex;
  align-items: center;
  padding: 1rem 1rem 0.5rem 1rem;
}

.header-title {
  font-weight: bold;
  flex: 2;
}

.menu_seperator {
  margin: 0.5rem 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.content {
  font-size: 0.85rem;
  padding: 1rem 1rem 0.5rem 1rem;
}

.section {
  margin-bottom: 2rem;
}

.custom-input {
  font: inherit;
  box-sizing: border-box;
  width: 6rem;
  max-width: 100%;
  border: none;
  border-bottom: 1px solid #ccc;
  padding: 0.7rem;
  font-size: 0.8rem;
}

.form__control {
  /* flex: 2; */
  border: black;
}

.form__control input:focus {
  background-color: rgba(107, 193, 250, 0.5);
  outline: none !important;
}

.form__control.invalid input {
  border: salmon;
  background-color: rgb(250, 128, 114, 0.5);
  border-bottom: 1px solid red;
}

.form__control.invalid label {
  color: red;
}

.form__control.invalid select {
  background: salmon;
  border: red;
}

.phone-numbers {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  height: 350px;
  overflow: scroll;
  border: solid 1.5rem rgba(#000, 1);
  overflow-x: hidden;
}

.phone-number {
  border-radius: 0.3rem;
  padding: 1.2rem;
  text-align: center;
  box-shadow: 1px 1px 2px rgb(0 0 0 / 10%);
  background-color: white;
}

.phone-number:hover {
  background-color: rgb(118, 234, 130);
  cursor: pointer;
}

.btn-round {
  display: inline-block;
  background-color: rgb(118, 234, 130);
  color: white;
  width: 1.5rem;
  height: 1.5rem;
  text-align: center;
  border-radius: 50%;
  border: none;
}

.btn-round.button--red {
  background-color: rgb(250, 128, 114, 1);
}

.btn-round:hover {
  cursor: pointer;
}

.form__control {
  background-color: var(--MediumGray-color);
  border-radius: 0.25rem;
  padding: 0.5rem;
}

.form__control textarea {
  width: 100%;
  border: none;
  font-family: inherit;
  resize: none;
  overflow: auto;
  background-color: transparent;
  overflow-y: hidden;
}

.form__control textarea:focus {
  outline: none;
}

.update-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
}
