.container:last-child > div {
  border: none;
}

.voice-message {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.progress-bar {
  margin-top: 2rem;
}

.audio-progress {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.details {
  display: flex;
  align-items: center;
}

.sender-details {
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
  flex: 2;
}

.sender-name {
  font-weight: 500; 
  font-size: 0.9rem;
}

.sender-number, .received { 
  display: flex;
  gap: 0.2rem;
  font-size: 0.75rem;
}

.play-read-controls {
  display: flex;
  gap: 0.5rem;
}

.controls {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 50%;
  width: 1.8rem;
  height: 1.8rem;
  padding: 0;
}

.button:hover {
  cursor: pointer;
}

.button:focus {
  outline: none;
  box-shadow: none;
}

.button--listen {
  background-color: rgba(255, 123, 23, 0.87);
}

.button--read {
  background-color: rgb(100, 141, 246);
}

.button--listen.active {
  background-color: gray;
}

.button--read.active {
  background-color: gray;
}

.voice-to-text {
  margin-top: 1rem;
}

.text {
  background-color: rgba(0, 0, 0, 0.05);
  padding: 1rem;
}

.voice-message input[type="range"] {
    --range-progress: 0;
    -webkit-appearance: none;
    position: relative;
    background:#ccc;
    width: 100%;
    height: 3px;
    border-radius: 2px;
    cursor: pointer;
  }
  
  /* Input range - firefox */
  .voice-message input[type="range"]::-moz-range-track {
    position: relative;
    background:#ccc;
    width: 100%;
    height: 3px;
    border-radius: 2px;
    cursor: pointer;
  }
  
  /* played progress length - Chrome & safari*/
  .voice-message input[type="range"]::before {
    content: '';
    height: 3px;
    background: rgb(88, 88, 88);
    width: var(--range-progress);
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  /* played progress length - firefox */
  .voice-message input[type="range"]::-moz-range-progress {
    background: rgb(88, 88, 88);
    border-bottom-left-radius: 2px;
    border-top-left-radius: 2px;
    height: 3px;
  }
  
  /* slider thumb - chrome and safari */
  .voice-message input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    border: none;
    background: rgb(123, 123, 123);
    cursor: pointer;
    position: relative;
  }
  
  /* dragging thumb - chrome and safari */
  .voice-message input[type="range"]:active::-webkit-slider-thumb {
    transform: scale(1.2);
  }
  
  /* slider thumb - firefox */
  .voice-message input[type="range"]::-moz-range-thumb {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: rgb(123, 123, 123);
    cursor: pointer;
    border: transparent;
    position: relative;
  }

  /* dragging thumb - firefox */
  .voice-message input[type="range"]:active::-moz-range-thumb {
    transform: scale(1.2);
  }

  .voice-message input[type="radio"] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: transparent;
    /* Not removed via appearance */
    margin: 0;
    font: inherit;
    color: currentColor;
    width: .9em;
    height: .9em;
    border: 0.10em solid rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }

  .voice-message input[type="radio"]::before {
    content: "";
    width: 0.35em;
    height: 0.35em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
  }

  .voice-message input[type="radio"]:checked::before {
    transform: scale(1);
    outline: none;
  }

  .voice-message input[type="radio"]:hover {
    cursor: pointer;
  }

  .call-control:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.answer-button {
    background-color: rgb(76, 232, 92);
    transform: rotate(240deg);
    padding: 0.5rem;
    border: none;
    filter: none;
}

.answer-button:hover {
    opacity: 0.8;
    background-color: rgb(76, 232, 92);
}

.hangup-button {
    background-color: rgb(251, 107, 107);
    border: none;
}

.hangup-button:hover {
    opacity: 0.8;
    background-color: rgb(251, 107, 107);
}

.mark-as-listened > input {
  background-color: var(--MediumGray-color);
}
