.main {
    transition: margin ease-in 300ms;
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 380px;
    height: calc(100vh - 44px);
    scrollbar-color: green;
}

@media (min-width: 768px) {
    .active {
        margin-left: 250px;
       
    }
}