.main {
    
}

.open-aside-arrow {
    display: flex;
    align-items: center;
    padding: .25rem;
    margin-top: 1rem;
    background-color: #3068ce;
    color: white;
    height: 2.5rem;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
    margin: auto;
    position: fixed;
    top: 50%;
    right: 0;
    transition: right 250ms linear;
    z-index: 999;
}

.active {
    right: 900px;
}

.nav-enter {
    animation: openSideDrawer 300ms linear forwards;
}

.nav-exit {
    animation: closeSideDrawer 300ms linear forwards;
}

@keyframes openSideDrawer {
    0% {
        opacity: 0;
        margin-left: -300px;
    }

    100% {
        opacity: 1;
        margin-left: 0;
    }
}

@keyframes closeSideDrawer {
    0% {
        opacity: 1;
        margin-left: 0;
    }

    100% {
        opacity: 0;
        margin-left: -300px;
    }
}

