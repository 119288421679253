.form-inputs {
    display: grid;
    text-align: left;
    grid-template-columns: repeat(auto-fill,minmax(200px, 1fr));
    gap: 2rem;
    margin: 1rem 0rem;
}

.form__actions {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
}

.form__actions button {
    flex: 0 0 auto;
}

.form__actions button:hover {
    opacity: .8;
}

.form__actions button:disabled{
    background-color: lightgray;
    color: darkgray;
    text-shadow: none;
} 

.form__actions button:disabled:hover {
    opacity: 1;
}

.form-inputs select {
    width: 15rem;
}

.form__actions__cancel-btn {
    color: gray;
    display: flex; 
    justify-content: center; 
    align-items: center;
    padding: 1rem;
    cursor: pointer;
}

@media (min-width: 768px) {
    .form-inputs {
        display: grid;
        text-align: left;
        grid-template-columns: repeat(auto-fill,minmax(200px, 1fr));
        gap: 2rem
    }
}