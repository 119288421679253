.addPipeline {
  padding: 3rem 3rem 0;
  width: 340px;
}

.actions {
  display: flex;
  gap: .25rem;
}

