.custom-input {
    font: inherit;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    border: none;
    border-bottom: 1px solid #ccc;
    padding: 0.7rem;
    font-size: .8rem;
}

.input-container i {
    position: absolute;
}

.form__control {
    border: black;
    width: 100%;
}

.form__control label {
    display: block;
    padding: 0.5rem;
}

.form__control input:focus {
    background-color: rgba(107, 193, 250, 0.5);  
    outline: none !important;
    border-radius: .25rem;
}

.form__control.invalid input {
    border: salmon;
    background-color: rgb(250, 128, 114, 0.5);
    border-bottom: 1px solid red;
    border-radius: .25rem;
}

.form__control.invalid label {
    color: red;
}

.form__control.invalid select {
    background: salmon;
    border: red;
}

input[name='email'] {
   /* add icons to inputs via css */
}

.error-message {
    font-size: .75rem;
    margin-top: .5rem;
    color: var(--text-color-red);
    display: flex;
}


/* input {
    font: inherit;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    border: 1px solid #ccc;
    border-radius: 0.2rem;
    padding: 0.7rem;
    font-size: .8rem;
}

.input-container i {
    position: absolute;
}

.form__control {
    border: black;
}

.form__control label {
    display: block;
    padding: 0.5rem;
}

.form__control input:focus {
    background-color: rgba(107, 193, 250, 0.5);  
    outline: none !important;
}

.form__control.invalid input {
    border: salmon;
    background-color: rgb(250, 128, 114, 0.5);
    border: 1px solid red;
}

.form__control.invalid label {
    color: red;
}

.form__control.invalid select {
    background: salmon;
    border: red;
}

input[name='email'] {
  
}
 */
