.message-form {
  position: sticky;
  bottom: 0;
  background-color: white;
  padding-bottom: 1rem;
}

.message-form > form {
  margin-top: 2rem;
}

.form-container {
  display: flex;
  border-radius: 1.5rem;
  outline: none;
  border: none;
  background-color: rgb(233, 238, 238);
  padding: .5rem 1rem;
  width: -webkit-fill-available;
  align-items: center;
  gap: 0.5rem;
}

.form-container > input {
  background-color: transparent;
  border: none;
  outline: none;
  width: 100%;
}

.message-button {
  border-radius: 50%;
  border: none;
  width: 2rem;
  height: 2rem;
  background-color: #3068ce;
  cursor: pointer;
}

/* .message-button:hover {
  background-color: rgb(60, 223, 60);
} */

.message-button:disabled {
  background-color: #cccccc;
  color: #666666;
  cursor:not-allowed;
}

.form__control {
  flex: 2;
}

.form__control textarea{
  width: 100%;
  border: none;
  font-family: inherit;
  resize: none;
  overflow: auto;
  background-color: transparent;
}

.form__control textarea:focus{
  outline: none;
}
