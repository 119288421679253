
.header {
    display: flex;
    flex-direction: column;
    padding: 1rem 1rem 1rem 1rem;
}

.header-title {
    font-weight: bold;
    flex: 2;
}

.menu_seperator {

    border-bottom: 1px solid rgba(0, 0, 0, 0.06)
}  

.content {
    font-size: 0.85rem;
    padding: 1rem 2rem;
}

.section {
    margin-bottom: 2rem;
}

.phone-gif {
    /* background: rgba(0, 0, 0, 0.03); */
    border-radius: 0.5rem;
    padding: 1rem;
    box-sizing: border-box;
    margin-top: 2rem;
    margin-bottom:1.5rem;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.09);
}

.number-gif {
    width: 100%;
    border-radius: 0.5rem;
    
}

.footer {
    display: flex;
    justify-content: flex-end;
    padding: 1rem;
}
