.event-item {
  display: flex;
  /* justify-content: space-around; */
  background-color: var(--MediumGray-color);
  border-radius: 0.25rem;
  padding: 0.35rem 0.75rem;
  margin: 0.25rem 0;
  align-items: center;
  cursor: pointer;
}

.delete-event {
  color: gray;
  padding: 0 0.25rem;    
  border-radius: 0.2rem;
  transition: 200ms all;
}

.delete-event:hover {
  background-color: rgba(161, 161, 161, 0.227);
  cursor: pointer;
}

.details {
  display: flex;
  align-items: center;
  flex: 2;
  overflow: hidden;
}

.title {
  /* color: #d1453b; */
  margin-left: 0.4rem;
  font-size: 0.75rem;
  color: gray;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  white-space: nowrap;
}

.appointment {
  /* background-color: #d4edda; */
}

.appointment .delete-event {
  color: rgb(0, 114, 11);
}

.follow-up {
  /* background-color: rgba(255, 234, 0, 0.46); */
}

.follow-up .delete-event {
  color: rgb(98, 90, 0);
}

.popper {
  opacity: 1;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0.2rem;
  z-index: 999;
  box-shadow: 1px 1px 15px rgba(0,0,0, 0.1);
  min-width: 275px;
}