.progress-bar-container {
    height: 1rem;
    background-color: lightgray;
    width: 100%;
    border-radius: 0.5rem;
}

.progress-bar__filler {
    height: inherit;
    text-align: right;
    border-radius: inherit;
}

.progress-bar__label {
    padding: 10px;
    margin: 0;
    color: white;
    font-weight: bold;
    font-size: .8rem;
}