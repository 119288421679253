html {
  --progress-width: 0%;
  --range-progress: 90%;
  --form-control-color: rgb(62, 62, 62);
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  min-height: 100%;
  background-color: #fff;
  overflow: hidden;
}

:root {
  --body-color: rgba(255, 255, 255);
  --text-color: rgb(36, 36, 36);
  --text-color-white: #fff;
  --text-color-red: #d1453b;
  --primary-color-blue: rgb(48, 104, 206);
  --DarkGray-color: rgba(0, 0, 0, 0.2);
  --MediumGray-color: rgba(0, 0, 0, 0.08);
  --LightGray-color: rgba(0, 0, 0, 0.04);
  --Active-color: rgb(118, 234, 130);
}

.dark-theme {
  --text-color: #d9d9d9;
  --text-color-red: #ff7066;
  --body-color: #202020;
  --sidebar-color: #242424;
  --DarkGray-color: #282828;
  --MediumGray-color: #adadad26;
  --LightGray-color: #f7f7f7;
  --contact-divider: hsla(0, 0%, 100%, 0.1);
  --border-color: #5a5a5a;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  font-size: .8rem;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  line-height: 1.4rem;
}

.m-0 {
  margin: 0rem !important 
}

.m-1 {
  margin: 1rem !important
}

.m-2 {
  margin: .5rem !important
}

.m-3 {
  margin: 1rem !important
}

.m-4 {
  margin: 1.5rem !important
}

.m-5 {
  margin: 3rem !important
}

.mt-0 {
  margin-top: 0rem !important;
}

.mb-0 {
  margin-bottom: 0rem !important;
}

.ml-0 {
  margin-left: 0rem !important;
}

.mr-0 {
  margin-right: .25rem !important;
}

.mt-1 {
  margin-top: .25rem !important;
}

.mb-1 {
  margin-bottom: .25rem !important;
}

.ml-1 {
  margin-left: .25rem !important;
}

.mr-1 {
  margin-right: .25rem !important;
}

.mt-2 {
  margin-top: .5rem !important;
}

.mb-2 {
  margin-bottom: .5rem !important;
}

.ml-2 {
  margin-left: .5rem !important;
}

.mr-2 {
  margin-right: .5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mb- {
  margin-bottom: 1rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.modal-animation-enter {
  animation: openModal 200ms ease-out forwards;
}

.modal-animation-exit {
  animation: closeModal 200ms ease-out forwards;
}

textarea {
  line-height: 1.5;
}

h3 {
  font-size: 1.17em;
}

@keyframes openModal {
  0% {
    opacity: 0
  }
  1000% {
    opacity: 1
  }
}
@keyframes closeModal {
  0% {
    opacity: 1
  }
  100% {
    opacity: 0
  }
}

