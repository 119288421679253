.close {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 0.2rem;
  transition: 200ms all;
}

.close:hover {
  background-color: rgba(228, 228, 228, 0.481);
  cursor: pointer;
}