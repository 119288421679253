.call-controls {
  display: flex;
  gap: 0.6rem;
  display: flex;
  justify-content: flex-start;
  border-radius: 0.4rem;
  align-items: center;
  margin-top: 0.5rem;
  background-color: var(--MediumGray-color);
  padding: 0.5rem;
  /* box-shadow: 1px 1px 1px rgb(211, 211, 211); */
  width: 166px;
  position: relative;
  margin: 0.5rem 2rem;
}

.call-control {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0.1rem;
  min-width: 15px;
  min-height: 15px;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 1px solid gray;
  border: none;
  color: white;
  cursor: pointer;
}

.callButtons,
.nextPrevButtons {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.prevButton,
.nextButton,
.muted-button {
  background-color: var(--MediumGray-color);
  color: black;
}

.call-control:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.answer-button {
  background-color: rgb(76, 232, 92);
  /* padding: 0.5rem; */
  border: none;
  filter: none;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
}

.answer-button:hover {
  opacity: 0.8;
  background-color: rgb(76, 232, 92);
}

.hangup-button {
  background-color: rgb(251, 107, 107);
  /* padding: 0.5rem; */
  border: none;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
}

.hangup-button:hover {
  opacity: 0.8;
  background-color: rgb(251, 107, 107);
}

.muted-button {
  border: none;
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  color: black;
}

.muted-button.active {
  color: var(--text-color-red);
}

.auxiliaries {
  display: flex;
  gap: 0.25rem;
  margin: auto 0px auto auto;
}

.autoDial,
.disposition {
  display: flex;
  align-items: center;
  color: #737373;
  border-radius: 0.25rem;
  box-sizing: content-box;
  position: relative;
  width: 1rem;
  height: 1rem;
}

.autoDial:hover,
.disposition:hover {
  cursor: pointer;
  background-color: var(--MediumGray-color);
}

.autoDial.active {
  color: lightgreen;
  background-color: var(--DarkGray-color);
}

.autoDial.paused {
  color: yellow;
}

.autoDial:hover p {
  display: block;
  transform-origin: 100% 0%;
  -webkit-animation: fadeIn 0.3s ease;
  animation: fadeIn 0.3s ease;
}
