.item {
  display: flex;
  flex-direction: column;
  border-right: 1px solid lightgray;
  font-size: 0.8rem;
  height: 100%;
  min-width: 25%;
  transition: .5s cubic-bezier(0.39, 0.575, 0.565, 1);
  position: relative;
}

.properties {
  border-bottom: 1px solid lightgray;
  padding: 0.5rem 0.5rem;
  gap: 0.5rem;
}

.key {
  flex: 1;
}

.value {
  color: rgb(122, 122, 122);
  padding: 1rem 0.5rem;
}

.item.caution {
  /* background-color: #f8d7da; */
  color: white;
}

.item > .properties.caution {
  background-color: var(--text-color-red)
}

.properties {
  display: flex;
  align-items: center;

}

.edit.caution > svg {
  filter: brightness(0) invert(1);
}

.popper {
  opacity: 1;
  background-color: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0.2rem;
  box-shadow: 1px 1px 15px rgb(0 0 0 / 10%);
  width: 25rem;
  position: absolute;
  z-index: 999;
}

.title {
  font-size: 0.8rem;
  font-weight: 500;
  padding: 1rem 1rem;
}

.menu_seperator {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}  

.form {
  padding: 1rem 1rem;
}

.form__control > select {
  padding: 0.5rem;
  width: 100%;
  outline: none;
  font-size: 0.8rem;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
  margin-top: 0.5rem;
}

.trash-icon, .edit-icon {
  cursor: pointer;
  color: gray;
}