.messages {
    display: flex;
    flex-direction: column;
}

.message {
    padding: 1rem;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06)
}

.message-details {
    display: flex;
    gap: .75rem;
    align-items: center;
    position: relative;
}

.icon-date {
    display: flex; 
    gap: 0.2rem;
}

.message:hover {
    cursor: pointer;
}

.message:last-child {
    border-bottom: none;
}

.message-header {
    display: flex; 
    align-items: center;
    gap: 0.2rem;
}

.icon {
    display: flex;
    align-items: center;
}

.icon > img {
    width: .8rem;
    height: .8rem;
}

.date {

}

.message-source {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.8rem;
    flex: 2;
}

.time-received {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.8rem;
}

.sender-details {
    display: flex;
    flex-direction: column;
    gap: 0.35rem;
    font-weight: 500;
    font-size: 0.9rem;
    flex: 2;
}

.count-expand {
    display: flex;
    gap: 0.5rem;
    align-items: center
}

 .last-message {
    display: flex;
    gap: 0.2rem;
    font-size: 0.75rem;
    color: gray;
 }

 .expand-messages {
     display: flex;
     justify-content: center;
     align-items: center;
     width: 15px;
     height: 15px;
 }
 .expand-messages:hover {
    cursor: pointer;
    background-color:rgba(0, 0, 0, 0.08);
    border-radius: 50%;
 }

 .expanded-messages {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-top: 1rem;
 }

 .expand-messages.active > svg {
    transform: rotate(-90deg)
 }

 .expand-messages.active {
    cursor: pointer;
    background-color:rgba(0, 0, 0, 0.08);
    border-radius: 50%;
 }

 .expanded-message {
    display: flex;
    align-items: center;
    gap: 0.2rem;
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 0.5rem; 
    padding: 0.7rem 1rem;
 }

 .expanded-message-details {
    display: flex;
    flex-direction: column;
    flex: 2;
    gap: 0.2rem;
 }

 .expanded-message:first-child {
    flex: 2;
 }

 .expanded-message:last-child {
    border-bottom: none;
 }

 .message-time {
    font-size: 0.6rem;
 }

 .expanded-message-text {
    flex: 2;
 }

 .unread-count {
    color: rgb(92, 91, 91);
    background-color: rgb(232, 229, 229);
    padding: 0.1em 0.4rem 0.1rem;
    border-radius: 0.35rem;
    font-weight: 500;
    font-size: 0.7rem;
}

.no-messages {
    padding: 1rem;
}

 .expand-messages > svg {
    pointer-events: none;
  }