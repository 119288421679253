.header {
    display: flex;
    justify-content: space-between;
    padding: 3rem 3rem 0.5rem 3rem; 
    margin: 0 auto;
    z-index: 999;
    margin-bottom: 3rem;
}

.title {
    flex: 1;
    width: 100px;
}

.title > h3{
    margin: 0;
    padding: 0;
}

.mode {
    display: flex;
    justify-content: center;
    height: 10%;
    flex: 2;
}

.next-today-prev {
    display: flex;
    justify-content: center;
    height: 10%;
    flex: 1;
    justify-content: flex-end;
}

.next-today-prev > div {
    display: flex;
    border: 1px solid rgba(214, 214, 214, .5);
    border-radius: 0.25rem;
    font-size: 0.8rem;
    padding: 0.1rem 0.2rem;
    align-items: center;
    cursor: pointer;
}

.next-today-prev > div:nth-child(2) {
    padding-left: 1rem;
    padding-right: 1rem;
    user-select: none;
}




