.side-drawer {
  position: fixed;
  height: calc(100vh - 44px);
  z-index: 11;
  width: 250px;
  background-color: var(--LightGray-color);
}

.side-drawer ul {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  list-style: none;
  padding: 1rem 0.4rem 1rem 1rem;
  font-size: 0.85rem;
  margin: 1rem 0;
}

.listItem {
  border-radius: 0.3rem;
  padding: 0.5rem 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.listItem:hover {
  background-color: var(--LightGray-color);
  cursor: pointer;
}

.listItem.current {
  background-color: var(--MediumGray-color);
}

.side-drawer a {
  text-decoration: none;
  color: rgb(36, 36, 36);
  display: block;
}

.icon {
  width: 18px;
  height: 18px;
}

.enter {
  animation: openSideDrawer 300ms ease-in forwards;
}

.exit {
  animation: closeSideDrawer 300ms ease-in forwards;
}

@keyframes openSideDrawer {
  0% {
    opacity: 0;
    margin-left: -300px;
  }

  100% {
    opacity: 1;
    margin-left: 0;
  }
}

@keyframes closeSideDrawer {
  0% {
    opacity: 1;
    margin-left: 0;
  }

  100% {
    opacity: 0;
    margin-left: -300px;
  }
}

/* .sidebar li {
    list-style: none;
    margin: 1rem 0.5rem;
}

.sidebar a {
    background-color: rgba(214, 214, 214, 0.5);
} */

/* .sidebar a{
    width: 5rem;
    text-decoration: none;
    list-style: none;
    padding: 1rem 0.5rem;
}

.sidebar a:hover,
.sidebar a:active {
    background-color: rgba(214, 214, 214, 0.5);
    border-radius: 0.3rem;
    box-sizing: border-box;
} */
