.dots {
    display: flex;
    gap: .9rem;
}

.dots button {
    width: .75rem;
    height: .75rem;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 0.3;
}

.unassigned{
    background-color:rgb(0, 0, 0, 0.4);
}

.red {
    background-color: rgb(233, 136, 136);
}

.yellow {
    background-color: rgb(255 234 0);  
}

.green {
    background-color: rgb(118, 234, 130);

}

#active {
    cursor: pointer;
    opacity: 1;

}