.drop-area {
    border: 2px dashed #ccc;
    border-radius: 0.5rem;
    font-family: sans-serif;
    padding: 1.5rem;
    position: relative;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.drop-area p {
    text-align: center;
    color: gray;
    font-size: 1.4rem;
    font-weight: bold;
    padding: 0;
    margin: 0;
    margin-bottom: 0.5rem;
}

.drop-area.highlight {
    border-color: purple;
  }

#fileElem {
    display: none;
}

.close-file-upload {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.fileUploadBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-color-red);
}

.fileUploadBtn:hover {
    text-decoration: underline;
    cursor: pointer;
}

.drop-area.inside-drop-area {
    background-color: lightgray;
}

.placeholder-image {
    margin-bottom: 1rem;
}