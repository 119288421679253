.container {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    /* grid-auto-flow: column; */
    /* grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); */
    /* border: 1px solid lightgray; */
    /* transform: translateX(); */
    border-top: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    border-left: 1px solid lightgray;
    border-right: 1px solid lightgray;
    height: 14rem;
    position: relative;
    z-index: 999;
}

.container:first-child {
    border-left: 1px solid lightgray;
}

.actions {
    /* display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    gap: 1rem; */
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.actions > div {
    background-color: rgba(0, 0, 0, 0.2);
    height: 100%;
    width: 1.5rem;
    transition: all 200ms;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}   

.actions > div:hover {
    opacity: 0.5;
    cursor: pointer;
}

.actions > :first-child {
    position: absolute;
    left: -2rem;
}

.actions > :nth-child(2) {
    position: absolute;
    right: -2rem;
}