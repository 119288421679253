.dial-pad {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.8);
  padding: 1.5rem 2rem;
  border-radius: 0.5rem;
  width: 300px;
  margin: 1rem;
  z-index: 999;
  color: white;
}

.inputs {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 0.75rem;
}

.input > button {
  background-color: transparent;
  border: none;
  color: white;
  outline: none;
  cursor: pointer;
}

.input {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.75rem;
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.2);
}

.input:hover {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
}

.input > div {
  font-size: 1.2rem;
}

.input > span {
  font-size: 0.6rem;
  cursor: pointer;
}

.break {
  flex-basis: 100%;
  height: 0;
}

.output {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  height: 15px;
}

.output:first-child {
  font-size: 1.2rem;
}

.delete-number {
  display: flex;
  cursor: pointer;
}

.call-buttons {
  display: flex;
  flex: 0 0 33.33%;
  align-items: center;
  padding-top: 1rem;
  justify-content: center;
}

.call-buttons > div {
  width: 50px;
}

.call-buttons > div:nth-child(2) {
  margin: 0 25px;
}

.call-buttons > div:nth-child(2) {
}

.call-buttons > div:nth-child(3) {
}

.call-button {
  all: unset;
  cursor: pointer;
}

.call-button {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.6);
}

.call-button:hover {
  opacity: 0.8;
}

.answer-button {
  background-color: rgb(73, 235, 89);
  transform: rotate(240deg);
}

.hangup-button {
  background-color: rgb(228, 84, 84);
}
