.main {
    width: 100%;
    min-width: 300px;
    overflow: auto;
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
  
  .main::-webkit-scrollbar {
    display: none;
  }
  
  .header {
    display: flex;
    align-items: center;
    padding: 1rem 1rem 0.5rem 1rem;
  }
  
  .header-title {
    font-weight: bold;
    flex: 2;
  }
  
  .menu_seperator {
    margin: 0.5rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  
  .content {
    font-size: 0.85rem;
    padding: 1rem 1rem 0.5rem 1rem;
  }
  
  .section {
    margin-bottom: 2rem;
  }
  
  .title {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    font-weight: bold;
  }

  .user {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin: 1rem 0;
  }
  
  .nameAndEmail {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .name:hover .edit,
  .email:hover .edit {
    display: block;
  }
  
  .name,
  .email {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
    width: 40%;
  }

  .name:hover, 
  .email:hover {
    cursor: pointer;
  }
  
  .name>p,
  .email>p,
  .delete>p {
    margin: 0;
    padding: 0;
  }
  
  .edit {
    display: none;
    cursor: pointer;
    height: 22px;
  }
  
  .name.editing {
    display: flex;
    gap: 0.25rem;
    align-items: center;
    position: relative;
    margin: 1rem 0;
  }
  
  .email.editing {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 1rem 0;
    gap: .5rem;
  }
  
  .name.editing>input {
    font: inherit;
    box-sizing: border-box;
    width: 100%;
    max-width: 100%;
    border: none;
    border-bottom: 1px solid #ccc;
    font-size: 0.8rem;
    background-color: transparent;
  }
  
  .name.editing>input:focus {
    outline: none !important;
  }
  
  .password {
    display: flex;
    gap: .25rem;
  }
  
  .password>span {
    width: .5rem;
    height: .5rem;
    background-color: gray;
    display: block;
    border-radius: 50%;
  }
  
  .actions {
    display: flex;
    gap: .5rem;
  }
  
  .save:hover {
    color: rgb(3, 228, 3);
    cursor: pointer;
  }
  
  .cancel:hover {
    color: red;
    cursor: pointer;
  }