.cal-body {
    font-size: .8rem;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    height: calc(100vh - 200px);
}

.cal-head {
    display: flex;
    gap: 0.25rem;
    padding: 0rem 0rem 0.5rem 3rem;
}

.cal-body::-webkit-scrollbar {
    display: none;
}

.time-table {
    display: flex;
}

.time-column {
    display: flex;
    flex-direction: column;
    width: 50px;
    justify-content: center;
    align-items: center;
    background-color: var(--MediumGray-color);
    border-top: 1px solid var(--MediumGray-color);
    /* margin-right: 0.25rem; */
    padding-left: 1rem;
}

.time-cells {
    font-size: .8rem;
    width: 100%;
}

.cal-body::-webkit-scrollbar {
    display: none;
}

/* .time-cells > div:nth-child(1) {
    border-left: none;
} */