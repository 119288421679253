.calendar-icon {
    display: flex;
    align-items: center;
    text-align: left;
}

/* default color */
.title {
    /* color: #d1453b; */
    margin-left: 0.4rem;
    font-size: 0.75rem;
    color: gray;
    display: flex;
    gap: 0.25rem;
    align-items: center;
}

.red {
    color: #d1453b
}

:global(.cal1) {
    stroke: #d1453b;
}

:global(.cal2){
    fill: #d1453b;
}

:global(.cal3) {
    fill: #d1453b;
}

/* appointment color */
:global(.EventItem_appointment__5\+F3R) :global(.cal1) {
    stroke: rgb(0, 114, 11);
}

:global(.EventItem_appointment__5\+F3R) :global(.cal2), :global(.EventItem_appointment__5\+F3R) :global(.cal3) {
    fill: rgb(0, 114, 11);
}

:global(.EventItem_appointment__5\+F3R) .title {
    color: rgb(0, 114, 11);
}

/* follow-up color */
:global(.EventItem_follow-up__yB-gN) :global(.cal1) {
    stroke: rgb(250, 229, 2);
}

:global(.EventItem_follow-up__yB-gN) :global(.cal2), :global(.EventItem_follow-up__yB-gN) :global(.cal3) {
    fill: rgb(250, 229, 2);
}

:global(.EventItem_follow-up__yB-gN) .title {
    color: rgb(171, 157, 0);
}




