.card {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    border-radius: 6px;
    background-color: white;
    overflow: hidden;
}

