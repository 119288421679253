.notes-form-container {
  flex: 0;
  box-sizing: border-box;
  padding: 1rem;
  border: 1px solid rgba(214, 214, 214, 0.349);
  border-radius: 0.5rem;
  background-color: white;
  margin-top: 2rem;
}

.form__control textarea {
  width: 100%;
  border: none;
  font-family: inherit;
  resize: none;
  overflow: auto;
  overflow-y: hidden;
  font-size: 0.8rem;
}

.form__control textarea:focus {
  outline: none;
}

.actions__container {
  display: flex;
  align-items: center;
}

.actions__buttons {
  display: flex;
  flex: 2;
  gap: 0.5rem;
  cursor: pointer;
}

.actions__container button {
  flex: 1;
  cursor: pointer;
}

.notes_actions:hover .actions__buttons {
  display: flex;
}

.actions__buttons > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
}

.actions__buttons > div:hover {
  background-color: rgba(226, 226, 226, 0.349);
  border-radius: 0.2rem;
}

.actions__buttons > div:hover * {
  filter: brightness(0%);
}

.uploading-files {
  display: flex;
  background-color: rgba(214, 214, 214, 0.349);
  border: 1px solid rgba(214, 214, 214, 0.63);
  padding: 0.25rem 0.75rem;
  border-radius: 0.2rem;
  margin-bottom: 0.5rem;
  align-items: center;
  font-size: 0.8rem;
}

.fileName {
  flex: 2;
}

.trash-icon:hover {
  cursor: pointer;
  background-color: rgba(226, 226, 226, 0.349);
  border-radius: 0.2rem;
}

.trash-icon {
  display: flex;
  align-items: center;
  padding: 0.05rem;
}
