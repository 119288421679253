.list-item {
    display: flex;
    flex-direction: column;
    padding: .8rem 1rem;
    gap: 1rem;
    max-height: 100%;
}

.list-item:hover {
    background-color: var(--LightGray-color);
    border-radius: 0.5rem;
    cursor: pointer;
}

.list-item.active {
    background-color: rgba(214, 214, 214, 0.2);
    border-radius: 0.5rem;
    cursor: pointer;
}

.list-item-bg {
    background-color: rgba(0, 0, 0, 0.02);
    border-radius: 0.5rem;
}

.list-item__contact-details {
    flex: 2;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.list-item__contact-details__wrapper {
    display: flex;
    gap: 1rem;
    align-items: center;
    cursor: pointer;
}

.list-item__contact-details__line-1 {
    display: flex;
    align-items: center;
    font-size: .9rem;
    color: rgba(0, 0, 0, 0.8);
    gap: .5rem;
    height: 25px;
}

.list-item__contact-details__name {
    flex: 2;
}

.list-item__actions__buttons {
    display: none;
    cursor: pointer;
}

/* .list-item__actions__buttons div:nth-child(4) {
    padding-right: 0;
} */

.list-item:hover .list-item__actions__buttons {
    display: flex;
}

.list-item__actions__buttons.active {
    display: flex;
}

.list-item__actions__buttons > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem;
}

.list-item__actions__buttons > div:hover {
    background-color: rgba(202, 202, 202, 0.349);
    border-radius: 0.2rem;
}

.list-item__actions__buttons > div:hover * {
    filter: brightness(0%);
}

.restore-contact-button {
    background-color: rgb(238, 238, 238);
    border-radius: 0.2rem;
    padding: 0.4rem;
    position: relative;
    font-size: 0.7rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    border: none;
}

.restore-contact-button:hover {
    background-color: rgb(196, 196, 196);
}

.list-item__contact-details__line-2 {
    display: flex;
    align-items: center;
    font-size: .8rem;
    gap: .5rem;
}

.notes__list-container {
    background-color: white;
    padding: 0 1rem;
    height: 250px;
    position: relative;
    overflow: scroll;
}

.notes-editor-container {
    flex: 0;
    box-sizing: border-box;
    padding: 1rem;
    border: 1px solid rgba(214, 214, 214, 0.349);
    border-radius: 0.5rem;
    margin: 1rem 0;
    background-color: white;
}

.note {
    margin: 2rem 0;
}

.note__user-details{
    display: flex;
    gap: 0.7rem;
    align-items: center;
    padding: 0 0;
}

.note__user-badge {
    /* background-color: rgb(44, 44, 44); */
    border: 1px solid black;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    text-align: center;
    vertical-align: middle;
    font-size: .8rem;
    line-height: 1.8;
    padding: 0.09rem;
    color:black;

}

.note__user-name {
    font-size: .9rem;
    font-weight: 500;
}

.note__date-time {
    display: flex;
    gap: 0.3rem;
    font-size: .7rem;
    color: gray;
}

.note__content {
    margin-left: 2.5rem;
    font-size: .9rem;
    line-height: 1.5rem;
}
.form-control textarea{
    width: 100%;
    border: none;
    font-family: inherit;
    resize: none;
    overflow: auto;
}

.form-control textarea:focus{
    outline: none;
}

.enter{
    animation: openNotes 100ms ease-out forwards;
  }
  
  .enterActive {
    animation: openNotes 200ms ease-out forwards;
  }
  
  .exit {
    animation: closeNotes 200ms ease-out forwards;
  }
  
  .exitActive {
    animation: closeNotes 200ms ease-out forwards;
  }

  @keyframes openNotes {
      0% {
        opacity: 0;
        margin-top: -20px;
      }
      100% {
        opacity: 1;
        margin-top: 0;
      }
  }
  
  @keyframes closeNotes {
    0% {
        opacity: 1;
        margin-top: 0
      }
      100% {
        opacity: 0;
        margin-top: -20px;
      }
  }