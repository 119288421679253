.view-mode {
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* gap: 0.5rem; */
    border: 1px solid rgba(214, 214, 214, .5);
    width: 200px;
    border-radius: 0.25rem;
    font-size: 0.8rem;

}

.view {
    cursor: pointer;
    flex: 1 100%;
    text-align: center;
    border-radius: 0.1rem;
    padding: 0.2rem;
    transition: all 350ms linear 100ms;
}

.horizontal-line {
    border: 0.5px solid rgba(214, 214, 214, .5);
    height: 60%;
}

.active {
    background-color: var(--MediumGray-color);
}