body {
    color: #1a202c;
    transition: color 0.2s ease-in;
}

main {
    height: 100vh;
    width: 100%;
    transition: background-color 0.2s ease-in;
}
