.week-cell {
    height: 3rem;
    border: 1px solid rgba(0, 0, 0, .1);
    border-bottom: none;
    border-right: none;
    box-sizing: border-box;
    position: relative;
    display: flex;
    /* gap: 0.25rem; */
    justify-content: space-between;
    /* overflow: hidden; */
    /* min-width: 100px; */
    transition: all 100ms linear;
}

.popper {
    opacity: 1;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 0.2rem;
    z-index: 999;
    box-shadow: 1px 1px 15px rgba(0,0,0, 0.1);
    min-width: 275px;
}

.event-item {
    border-radius: 0.25rem;
}

.week-cell.disabled {
    background-color: rgba(0, 0, 0, 0.04);
}

.isOver {
    background-color: var(--LightGray-color);
}