.contact-communications {
    padding: 1rem 2rem;
}

.contact-communications__menu {
    display: flex;
    justify-content: space-around;
    font-size: .8rem;
    color: rgb(143, 143, 143);
    text-align: center;
    margin-bottom: 0.5rem;
}

.contact-communications__menu > div:hover {
    cursor: pointer;   
}

.contact-communications__menu > a {
    flex: 1;
    border-bottom: 1px solid var(--MediumGray-color);
    padding-bottom: 0.5rem;
    text-decoration: none;
    text-decoration: none;
    color: inherit;
}

.contact-communications__menu > a:visited {
    text-decoration: none;
    color: inherit;
}

.contact-communications__menu > .active {
    border-bottom: 1px solid black;
    color: black;
}

.contact-communications__menu > a.active  {
    border-bottom: 1px solid #3068ce;
    color: black;
}