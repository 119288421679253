.dot {
    height: 1rem;
    width: 1rem;
    border: none;
    border-radius: 50%;
    cursor: pointer;
}

.unassigned{
    background-color:rgb(0, 0, 0, 0.4);
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 0.3;
}

.red {
    background-color: rgb(233, 136, 136);
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 0.3;
}

.yellow {
    background-color: rgb(255 234 0);
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 0.3;
}

.green {
    background-color: rgb(118, 234, 130);
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 0.3;
}

.blue { 
    background-color: #3068ce;
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 0.3;
}

.large {
    width: 16px;
    height: 16px;
}

.medium {
    width: 14px;
    width: 14px;
}

.small {
    width: 12px;
    height: 12px;
}

.x-small {
    width: 8px;
    height: 8px;
}

#disabled {
    opacity: .3;
}

.disabled {
    background-color:rgb(0, 0, 0, 0.4);
    transition: opacity 0.3s;
    -webkit-transition: opacity 0.3s;
    opacity: 0.3;
}

.unassigned:hover, .red:hover, .yellow:hover, .green:hover, .blue:hover {
    opacity: 1;
}

.unassigned:focus, .red:focus, .yellow:focus, .green:focus, .blue:hover {
    outline: none;
}

.isActive {
  opacity: 1;  
}

.isOnline {
    opacity: 1;
    animation: breathe 3s linear infinite;
    -webkit-background-clip: padding-box;
    -moz-background-clip: padding;
    background-clip: padding-box;
}

@keyframes breathe {
    0% {box-shadow: 0 0 0 0px rgba(118, 234, 130, 0.0)}
    50% {box-shadow: 0 0 0 0px rgba(118, 234, 130, 0.4)}
    60% {box-shadow: 0 0 0 5px rgba(118, 234, 130, 0.2)}
    100% {box-shadow: 0 0 0 15px rgba(118, 234, 130, 0.0)}
  }