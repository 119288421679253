.message-notification {
    position: fixed;
    z-index: 999;
    top: 3.5rem;
    right: 0.5rem;
}

.message {
    position: relative;
    width: 350px;
    background-color: rgba(255, 255, 255);
    padding: 0.8rem;
    border-radius: 0.5rem;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
    margin-bottom: 1rem;
    cursor: pointer;
}

.message-notification:hover .actions {
    display: flex;
}

.header {
    display: flex; 
    align-items: center;
    gap: 0.2rem;
}

.icon {
    display: flex;
    align-items: center;
}

.message-source {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.8rem;
    flex: 2;
}

.time-received {
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.8rem;
}

.actions {
    /* display: none; */
    cursor: pointer;
}

.close {
    position: absolute;
    top: -0.5rem;
    left: -0.5rem;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
    border-radius: 50%;
    height: 0.5rem;
    width: 0.5rem;
    padding: 0.3rem;
    background-color: rgba(0, 0, 0, 0.08);
    line-height: 0.3rem;
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.6);
}

.options {
    position: absolute;
    bottom: 0;
    right: 0;
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.6);
    background-color: rgba(0, 0, 0, 0.05);
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
    padding: 0.2rem 0.5rem;
    border-radius: 0.2rem;
    margin: 0.5rem;
}

.sender-name {
    font-weight: 500;
    margin: 0.5rem 0 0 0;
    font-size: 0.9rem;
}

.sender-message {
    font-size: 0.8rem;
}

.enter {
    opacity: 0;
  }

.enter-active {
    opacity: 1;
    transition: opacity 200ms;
}

.exit {
    opacity: 1;
}

.exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
}
