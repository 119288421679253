.spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.loader {
    border-radius: 50%;
    animation: spin 2s linear infinite;
}

.large {
    width: 120px;
    height: 120px;
    border: 5px solid lightgray;
    border-top: 5px solid gray;
}

.medium {
    width: 60px;
    height: 60px;
    border: 4px solid lightgray;
    border-top: 4px solid gray;
}

.small {
    width: 30px;
    height: 30px;
    border: 3px solid lightgray;
    border-top: 3px solid gray;
}

.x-small {
    width: 15px;
    height: 15px;
    border: 2px solid lightgray;
    border-top: 2px solid gray;
}

@keyframes spin {
    0% { 
        transform: rotate(0deg) 
    }
    100% {
        transform: rotate(360deg)
    }
}