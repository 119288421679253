
.note {
    margin: 2rem 0;
}

.user-details{
    display: flex;
    gap: 1rem;
    align-items: center;
    padding: 0 0;
    height: 1rem;  
    margin-bottom: 0.5rem;
}

.user-name {
    font-size: .9rem;
    font-weight: bold;
}

.date-time {
    display: flex;
    gap: 0.3rem;
    font-size: .7rem;
    color: gray;
    flex: 1;
}

.content {
    margin-left: 2.5rem;
    font-size: .8rem;
    line-height: 1.2rem;
}

.content p {
    margin-top: 0;
    padding-top: 0;
}

.content:hover {
    cursor: pointer;
}

.image-file:hover {
    cursor: -moz-zoom-in; 
    cursor: -webkit-zoom-in; 
    cursor: zoom-in;
}

.downloadable-file {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    border: 1px solid rgb(243, 243, 243);
    padding: 0.4rem;
    padding-right: 0.5rem;
    border-radius: 0.3rem;
    width: max-content;
    transition: all 300ms;
}

.downloadable-file:hover {
    background: rgb(241, 241, 241);
    color:rgb(204, 86, 70);
    cursor: pointer;
    border: 1px solid rgb(165, 165, 165);
}

.downloadable-file:hover > a{
    color:rgb(207, 66, 47);
}

.downloadable-file a {
    color:rgb(0, 0, 0, 0.4);
    text-decoration: none;
    font-weight: bold;
    font-size: .7rem;
    transition: all 300ms;
}

.downloadable-file img {
    width: 30px;
    height: 30px;
}

.downloadable-file {
    margin-bottom: 0.5rem;
    min-width: 250px;
    max-width: 80%
}

.actions-buttons {
    display: none;
    cursor: pointer;
}

.note:hover .actions-buttons {
    display: flex;
}

.actions-buttons:hover {
    display: flex;
}

.actions-buttons > div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem;
}

.actions-buttons > div:hover {
    background-color: rgba(226, 226, 226, 0.349);
    border-radius: 0.2rem;
}

.trash-icon, .edit-icon {
    cursor: pointer;
    color: gray;
}

.container {
    flex: 0;
    box-sizing: border-box;
    padding: 1rem;
    border: 1px solid rgba(214, 214, 214, 0.349);
    border-radius: 0.5rem;
    margin: 1rem 0;
    background-color: white;
}

.update-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem
}

.form__control textarea{
    width: 100%;
    border: none;
    font-family: inherit;
    resize: none;
    overflow: auto;
    overflow-y: hidden;
}

.form__control textarea:focus{
    outline: none;
}

.notes__actions__container {
    display: flex;
    align-items: center;
}