.day-container {
    border: 1px solid rgba(0, 0, 0, .1);
    display: flex;
    /* justify-content: end; */
    padding: 0.4rem;
    border-bottom: none;
    border-right: none;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
    flex-direction: column;
    position: relative;
    font-size: 0.8rem;
    padding-top: 3rem;
    min-height: 175px;
    min-width: 0;
    transition: all 100ms linear;
}

.day {
    height: .85rem;
    width: .85rem;
    padding: .7rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0.4rem;
    right: 0.4rem;
}

.day-container.disabled > .day {
    color: #9b9b9b
}

.day-container.disabled {
    background-color: rgba(0, 0, 0, 0.04);
}

.day.active {
    color: white;
    background-color: #3068ce;
}

.day-container.isOver {
    background-color: var(--LightGray-color);
}

.see-more {
    margin: .25rem; 
    font-size: 0.75rem;
}

.see-more:hover {
    cursor: pointer;
}

.popper {
    opacity: 1;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.06);
    border-radius: 0.2rem;
    z-index: 999;
    box-shadow: 1px 1px 15px rgba(0,0,0, 0.1);
    min-width: 275px;
}

.day-container:nth-child(1) {
    border-left: none;
}

.day-container:nth-child(8) {
    border-left: none;
}

.day-container:nth-child(15) {
    border-left: none;
}

.day-container:nth-child(22) {
    border-left: none;
}

.day-container:nth-child(29) {
    border-left: none;
}

.day-container:nth-child(36) {
    border-left: none;
}

.day-container:nth-child(7) {
    border-right: none;
}
