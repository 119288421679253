.stage {
  display: flex;
  flex-direction: column;
  min-width: 280px;
  position: relative;
  box-sizing: border-box;
  margin-right: 2rem;
  padding: .5rem;
  border-radius: .25rem;
  background-color: var(--body-color);
  border: 2px solid transparent;
  transition: border 100ms linear;
  height: fit-content;
}

.header {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: .9rem;
}

.stage:has(.header:hover), .isDragging {
  box-shadow: 0px 0px 5px var(--LightGray-color);
  border-color: var(--LightGray-color);
}

.stageName {
  flex: 2;
}

.dragginOver {
  background-color: var(--MediumGray-color);
}

.addDeal {
  margin-bottom: .5rem;
}

.kebab {
  color: rgba(0, 0, 0, 0.5)
}

.kebabMenu {
  transform: rotate(90deg);
}

.kebabMenu:hover {
  cursor: pointer;
}

.actions {
  display: flex;
  gap: .25rem;
}

.popper {
  opacity: 1;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0.2rem;
  z-index: 999;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  min-width: 250px;
}

.editStageMenu {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: .3rem;
}

.editStageMenu li {
  list-style: none;
  margin: 0;
  padding: .5rem;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: .5rem;
  font-size: .8rem;
  padding: .25rem;
  border-radius: .25rem;
}

.menuItem:hover {
  background-color: var(--MediumGray-color);
  overflow: hidden;
  cursor: pointer;
}

.trash-icon,
.edit-icon {
  cursor: pointer;
  color: gray;
}

.input:focus {
  outline: none;
}

.dealList {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem .25rem; 
  border-radius: .25rem;
  margin: .5rem 0rem;
}

.deal {
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(227, 227, 227);
  border-radius: .25rem;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, .05);
  margin-left: .25rem;
  width: 245px;
  box-sizing: border-box;
  margin-bottom: .75rem;
}

.contactDetails {
  display: flex;
  flex-direction: column;
  gap: .35rem;
  padding: .75rem;
}

.contactName {
  font-size: .85rem;
  flex: 2;
}

.contactNameInput,
.descriptionInput {
  font-size: .85rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.descriptionInput {
  font-size: .7rem;
}

.descriptionInput input::placeholder {
  color: rgba(0, 0, 0, .4);
}

.contactNameInput input[type="search"],
.descriptionInput input[type="text"] {
  font: inherit;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  border: none;
  border-radius: 0.25rem;
}

.contactNameInput input:focus,
.descriptionInput input:focus {
  outline: none !important;
  border-radius: .25rem;
}

.form-control.invalid input[type="search"],
.form-control.invalid input[type="text"] {
  background-color: rgb(250, 128, 114, 0.5);
  border-bottom: 1px solid red;
  padding: 0.7rem;
}

.horizontalLine {
  border-top: 0.5px solid rgba(214, 214, 214, .5);
}

.newDeal {
  display: flex;
  justify-content: flex-end;
  padding: 0rem 0.75rem;
}

.filteredContactsList {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  font-size: 0.8rem;
  /* background-color: var(--DarkGray-color); */
  padding: 0.5rem;
  border-top: 1px solid #3068ce;
  background-color: rgb(241 241 241);
  position: absolute;
  z-index: 1;
  right: 0;
  left: 0;
  box-sizing: border-box;
  margin: 0 15px;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  max-height: 250px;
  overflow: scroll;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.filteredContactsList>div {
  padding: .5rem .5rem;
  border-radius: .25rem;
}

.filteredContactsList>div:hover {
  background-color: var(--MediumGray-color);
  cursor: pointer;
}

.filteredContactsList::-webkit-scrollbar {
  display: none
}

.add-new-contact {
  cursor: pointer;
}

.addNewContact span {
  color: #d1453b;
}

.addedContact {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}

.contact {
  display: flex;
  gap: 0.25rem;
  flex: 2;
  align-items: center;
}

.contact:hover {
  cursor: pointer;
}

.selectedContact {
  flex: 2;
}

.callControls {
  display: flex;
  gap: 0.3rem;
  margin-right: .3rem;
}

.answerButton,
.hangupButton,
.muteButton {
  background-color: rgb(76, 232, 92);
  border: none;
  border-radius: 50%;
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.hangupButton {
  background-color: rgb(251, 107, 107);
  transform: rotate(135deg);
}

.muteButton {
  background-color: transparent;
  border: 1px solid white;
}

.answerButton:hover,
.hangupButton:hover {
  cursor: pointer;
}

.callMuted {
  filter: invert(43%) sepia(87%) saturate(2136%) hue-rotate(339deg) brightness(100%) contrast(103%);
}