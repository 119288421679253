.event-scheduler-popup {
  font-size: 0.8rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 0.5rem 1rem;
}

.menu {
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
  gap: 0.85rem;
}

.menu__item {
  font-size: 0.8rem;
}

.note {
  border-radius: 0.25rem;
  background-color: var(--MediumGray-color);
  padding: 0.5rem;
}

.react-calendar {
  display: flex;
  flex-direction: column;
  background-color: white;
  position: absolute;
  top: 140px;
  z-index: 999;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.09);
  border-radius: 0.5rem;
  padding: 1.25rem;
  box-sizing: border-box;
  width: 330px;
  height: 285px;
}

.date-picker {
  display: flex;
  flex-direction: column;
}

.time-picker {
  display: flex;
  justify-content: space-between;
  color: rgb(80, 80, 80);
  gap: 0.5rem;
}

.reminder-picker {
  gap: 1rem;
}

.event-types {
  display: flex;
  gap: 0.5rem;
}

.event-types > .form-control > input[type="radio"] ~ label {
  padding: 0.5rem;
  border: 1px solid var(--text-color);
}

.event-types > .form-control > input[type="radio"]:checked ~ label {
  border: 1px solid var(--text-color);
  background-color: #3068ce;
  color: white;
}

.start-time-dropdown,
.end-time-dropdown,
.calendar-dropdown {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  cursor: pointer;
  width: 50%;
  box-sizing: border-box;
  position: relative;
}

.start-time-dropdown,
.end-time-dropdown {
  width: 150px;
}

.start-time-dropdown-inner,
.end-time-dropdown-inner,
.calendar-dropdown-inner {
  display: flex;
  gap: 0.5rem;
  border: 1px solid var(--text-color);
  padding: 0.1rem 1rem;
  border-radius: 0.5rem;
  justify-content: space-around;
  align-items: center;
  color: rgb(80, 80, 80);
}

.calendar-dropdown {
  width: 100%;
  margin-bottom: 1rem;
}

.start-time-list,
.end-time-list {
  display: flex;
  gap: 0.25rem;
  position: absolute;
  background: white;
  width: 100%;
  top: 50px;
  justify-content: center;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.09);
  border-radius: 0.5rem;
  z-index: 999;
  height: 240px;
}

.start-time-list::before,
.end-time-list::before {
}

.start-time,
.end-time {
  position: relative;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.start-time::-webkit-scrollbar,
.end-time::-webkit-scrollbar,
.filtered-contacts-list::-webkit-scrollbar {
  display: none;
}

.am-pm {
  padding-top: 0.5rem;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: column;
}

.time-picker-button {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  margin-top: auto;
  border: none;
  padding: 0.25rem;
  background-color: #3068ce;
  color: white;
  border-radius: 0.25rem;
}

.form-control {
  display: flex;
  align-items: center;
}

.form-control > label {
  cursor: pointer;
  transition: background-color 0.25s linear 0.25ms;
  padding: 0.25rem;
  border-radius: 0.25rem;
}

.form-control > input[type="radio"] {
  visibility: hidden;
  background-color: transparent;
  margin: 0;
  font: inherit;
  color: currentColor;
  cursor: pointer;
  position: absolute;
  border-radius: 0.25rem;
}

.form-control > input[type="radio"]:disabled ~ label {
  opacity: 0.3;
}

.form-control > input[type="radio"]:checked ~ label {
  background-color: rgb(237, 237, 237);
  border-radius: 0.25rem;
}

.form-actions {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.delete {
  color: gray;
  flex: 2 1 0%;
  color: #d1453b;
  text-decoration: underline;
  font-size: 0.8rem;
}

.delete span {
  cursor: pointer;
}

.menu-separator {
  border-bottom: 1px solid #ddd;
}

.add-contact > div {
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  width: 100px;
}

.added-contact {
  display: flex;
  gap: 0.25rem;
  align-items: center;
}

.contact {
  display: flex;
  gap: 0.25rem;
  flex: 2;
  align-items: center;
}

.contact:hover {
  cursor: pointer;
}

.filtered-contacts-list {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  font-size: 0.8rem;
  /* background-color: var(--DarkGray-color); */
  padding: 0.5rem;
  border-top: 1px solid #3068ce;
  background-color: rgb(243, 243, 243);
  position: absolute;
  z-index: 999;
  right: 0;
  left: 0;
  box-sizing: border-box;
  margin: 0 15px;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  max-height: 250px;
  overflow: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.filtered-contacts-list > div {
  padding: 0.5rem 0.5rem;
  border-radius: 0.25rem;
}

.filtered-contacts-list > div:hover {
  background-color: var(--MediumGray-color);
  cursor: pointer;
}

.add-new-contact {
  cursor: pointer;
}

.add-new-contact span {
  color: var(--text-color-red);
}

.event-scheduler-popup input[type="search"],
.event-scheduler-popup input[type="text"] {
  font: inherit;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  border: none;
  border-radius: 0.25rem;
}

.form-control.invalid input[type="search"],
.form-control.invalid input[type="text"] {
  background-color: rgb(250, 128, 114, 0.5);
  border-bottom: 1px solid red;
  padding: 0.7rem;
}

.event-scheduler-popup input:focus {
  outline: none !important;
  border-radius: 0.25rem;
}

.event-name {
  font-size: 0.9rem;
  font-weight: 500;
}

.text-area-element {
  resize: none;
  overflow: hidden;
  min-height: 5px;
  max-height: 150px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.05);
  border: none;
  font-family: inherit;
  padding: 0.5rem;
  box-sizing: border-box;
  border-radius: 0.5rem;
  line-height: 1.5rem;
}

.text-area-element:focus {
  background-color: rgba(107, 193, 250, 0.5);
  outline: none !important;
  border-radius: 0.25rem;
}

.call-controls {
  display: flex;
  gap: 0.3rem;
  margin-right: 0.3rem;
}

.answer-button,
.hangup-button,
.mute-button {
  background-color: rgb(76, 232, 92);
  border: none;
  border-radius: 50%;
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.hangup-button {
  background-color: rgb(251, 107, 107);
  transform: rotate(135deg);
}

.mute-button {
  background-color: transparent;
  border: 1px solid white;
}

.answer-button:hover,
.hangup-button:hover {
  cursor: pointer;
}

.call-muted {
  filter: invert(43%) sepia(87%) saturate(2136%) hue-rotate(339deg)
    brightness(100%) contrast(103%);
}

:global(.react-calendar__navigation) {
  display: flex;
  margin-bottom: 1.5rem;
}

:global(.react-calendar__navigation__next2-button) {
  display: none;
}

:global(.react-calendar__navigation__prev2-button) {
  display: none;
}

:global(.react-calendar__navigation__next-button) {
  background-color: transparent;
  box-shadow: none;
  border: none;
  transition: background-color 0.25s ease 0.25ms;
  border-radius: 1rem;
}

:global(.react-calendar__navigation__prev-button) {
  background-color: transparent;
  box-shadow: none;
  border: none;
  transition: background-color 0.25s ease 0.25ms;
  border-radius: 1rem;
}

:global(.react-calendar__navigation__next-button:hover) {
  background-color: rgb(231, 231, 231);
}

:global(.react-calendar__navigation__prev-button:hover) {
  background-color: rgb(231, 231, 231);
}

:global(.react-calendar__navigation__label) {
  background-color: transparent;
  box-shadow: none;
  border: none;
}

:global(.react-calendar__month-view__days__day) {
  background-color: transparent;
  border: none;
}

:global(.react-calendar__tile--range) {
  background-color: rgb(237, 237, 237);
  border: none;
  border-radius: 0.5rem;
  transition: background-color 0.5s ease 0.25ms;
}

:global(.react-calendar__tile):hover {
  cursor: pointer;
}

:global(.react-calendar__month-view__weekdays) {
  margin-bottom: 1rem;
}

:global(.react-calendar__month-view__weekdays__weekday--weekend) {
  text-align: center;
}

:global(.react-calendar__month-view__weekdays__weekday) {
  text-align: center;
}

:global(.react-calendar__month-view__days) {
}

:global(.react-calendar__month-view__days__day) {
  height: 2rem;
}

abbr[aria-label] {
  text-decoration: none;
  font-size: 0.7rem;
}

:global(.react-calendar__month-view__days__day--neighboringMonth) {
  opacity: 0.3;
}
