.dealModal {
  height: inherit;
}

.dealModalHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  padding: 0.5rem 1rem;
}

.breadcrumbs {
  display: flex;
  gap: 1rem;
  font-size: 0.9rem;
  color: rgb(68, 68, 68);
  height: 2rem;
}

.headerActions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.detailsAndAside {
  display: flex;
  height: calc(100% - 3rem);
  /* 0ffset by header */
}

.details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  /* flex: 0 0 70%; */
  width: 100%;
  overflow-y: auto;
  margin-right: -15px;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  padding: 2rem 2rem;
  box-sizing: border-box;
}

.details::-webkit-scrollbar {
  display: none;
}

.aside {
  background-color: rgba(0, 0, 0, 0.05);
  width: 100%;
  padding: 1rem 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow-y: auto;
  box-sizing: border-box;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  flex: 0 0 30%;
  height: 100vh;
}

.contactAndDescription {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
}

.fullName {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  font-weight: 500;
}

.description {
  font-size: 0.75rem;
  color: gray;
}

.descriptionInput .formControl {
  border: 1px solid gray !important;
  height: 100%;
  min-height: 50px;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.iconAndDesc {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.tasklistIncompleted,
.tasklistCompleted {
  padding: 0rem 1rem;
}

.tasklistCompleted {
}

.addTasks {
}

.addTask {
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(227, 227, 227);
  border-radius: 0.25rem;
  box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.05);
  margin-left: 0.25rem;
  box-sizing: border-box;
}

.taskDetails {
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
}

.taskNameInput,
.descriptionInput,
.iconAndDesc,
.taskDescriptionInput {
  font-size: 0.85rem;
  color: gray;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.taskDescriptionInput {
  font-size: 0.7rem;
}

.descriptionInput input::placeholder,
.taskDescriptionInput input::placeholder {
  color: rgba(0, 0, 0, 0.4);
}

.taskNameInput input[type="text"],
.descriptionInput input[type="text"],
.iconAndDesc input[type="text"],
.taskDescriptionInput input[type="text"] {
  font: inherit;
  box-sizing: border-box;
  width: 100%;
  max-width: 100%;
  border: none;
  border-radius: 0.25rem;
}

.taskNameInput input:focus,
.descriptionInput input:focus,
.iconAndDesc input:focus,
.taskDescriptionInput input:focus {
  outline: none !important;
  border-radius: 0.25rem;
}

.horizontalLine {
  border-top: 0.5px solid rgba(214, 214, 214, 0.5);
}

.actions {
  display: flex;
  gap: 0.25rem;
}

.newTaskActions,
.descriptionActions {
  display: flex;
  justify-content: flex-end;
  padding: 0rem 0.75rem;
}

.descriptionInput {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.contact-communications {
  padding: 1rem 2rem;
}

.contact-communications__menu {
  display: flex;
  justify-content: space-around;
  font-size: 0.8rem;
  color: rgb(143, 143, 143);
  text-align: center;
  margin-bottom: 0.5rem;
}

.contact-communications__menu > div:hover {
  cursor: pointer;
}

.contact-communications__menu > div {
  flex: 1;
  border-bottom: 1px solid var(--MediumGray-color);
  padding-bottom: 0.5rem;
  text-decoration: none;
  text-decoration: none;
  color: inherit;
}

.contact-communications__menu > a:visited {
  text-decoration: none;
  color: inherit;
}

.contact-communications__menu > .active {
  border-bottom: 1px solid black;
  color: black;
}

.contact-communications__menu > div.active {
  border-bottom: 1px solid #3068ce;
  color: black;
}

/* .formControl textarea {
    width: 100%;
    border: none;
    font-family: inherit;
    resize: none;
    overflow: auto;
    overflow-y: hidden;
}

.formControl textarea:focus {
    outline: none;
}

.formControl .invalid input[type="text"] {
    background-color: rgb(250, 128, 114, 0.5);
    border-bottom: 1px solid red;
    padding: 0.7rem;
} */

.aside-item {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.aside-title {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
}

.aside-value {
  margin: 0;
  padding: 0;
  font-size: 0.75rem;
  color: gray;
  font-weight: 400;
}

.aside-item p {
  line-height: 1.5rem;
}

.aside-item p:nth-child(2) {
  color: gray;
  font-size: 0.75rem;
}

.menuSeparator {
  margin: 0.5rem 0rem;
  border-bottom: 1px solid #ddd;
}

.aside-enter {
  animation: openAside 500ms forwards;
}

.aside-exit {
  animation: closeAside 500ms forwards;
}

.openAsideArrow {
  display: flex;
  align-items: center;
  padding: 0.25rem;
  margin-top: 1rem;
  background-color: #3068ce;
  color: white;
  height: 3rem;
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  margin: auto;
}

.openAsideArrow:hover {
  cursor: pointer;
}

@keyframes openAside {
  0% {
    margin-right: -270px;
  }

  100% {
    margin-right: 0;
  }
}

@keyframes closeAside {
  0% {
    margin-right: 0;
  }

  100% {
    margin-right: -270px;
  }
}
