.list-box__header {
  padding: 3rem 0rem 0.5rem 0rem;
  margin: 0 auto;
  border-bottom: 1px solid rgb(243, 243, 243);
  margin-bottom: 0.5rem;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}

.list-box__filter {
  display: flex;
  flex-direction: column;
}

.list-box__actions,
.list-box__status {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  font-size: 0.8rem;
  padding-right: 0.5rem;
}

.list-box__filters__search-bar {
  display: flex;
  margin-bottom: 0.5rem;
  width: 100%;
}

.list-box__filters__active,
.list-box__filter__container {
  display: flex;
  gap: 0.3rem;
  position: relative;
  flex-wrap: wrap;
}

.list-box__filters__active__filter,
.list-box__filters__filter {
  background-color: rgb(238, 238, 238);
  border-radius: 0.2rem;
  padding: 0.4rem;
  position: relative;
  font-size: 0.7rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  align-items: center;
}

.list-box__filters__filter.active {
  background-color: rgb(196, 196, 196);
}

.list-box__filters__active__filter:after {
  display: inline-block;
  content: "\00d7"; /* This will render the 'X' */
  margin-left: 0.5rem;
}

.list-box__filters__add-filter {
  background-color: #3068ce;
  color: rgba(0, 0, 0, 0.5);
  padding: 0.2rem 1.4rem;
  font-size: 0.7rem;
  border-radius: 0rem 0.2rem 0.2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  font-size: 1rem;
  cursor: pointer;
}

.resultsCount {
  display: flex;
  gap: 0.2rem;
  align-items: center;
  justify-content: center;
  /* margin-left: auto; */
  font-size: 0.7rem;
  padding: 0.4rem;
  color: rgba(0, 0, 0, 0.5);
  border-radius: 0.2rem;
  background-color: #3068ce;
  color: white;
  font-weight: 500;
}

.list-box__filters-header {
  display: flex;
  margin-bottom: 1rem;
  font-size: 0.9rem;
  font-weight: 500;
}

.list-box__filters-header__title {
  flex: 1;
  display: flex;
  align-items: center;
}

.list-box__filter__title {
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
  display: block;
}

.list-box__header input[type="text"] {
  background-color: transparent;
  border: none;
  /* border-bottom: 1px solid #ccc; */
  width: 3rem;
  background-color: rgb(238, 238, 238);
  color: rgba(0, 0, 0, 0.5);
  padding: 0.4rem;
  border-radius: 0.2rem;
}

.list-box__header input[type="text"]:focus {
  outline: none;
}

.formControl.invalid input {
  border: salmon;
  background-color: rgb(250, 128, 114, 0.5);
  border-bottom: 1px solid red;
  border-radius: 0.25rem;
}

.error-message {
  font-size: 0.75rem;
  margin-top: 0.5rem;
  color: var(--text-color-red);
  display: flex;
}

.search {
  border: none;
  border-radius: 0.2rem 0 0 0.2rem;
  background-color: rgb(238, 238, 238);
  height: 2.5rem;
  width: 100%;
  outline: none;
  padding: 0.5rem;
}

.popper {
  opacity: 1;
  background-color: white;
  border: 0px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.2rem;
  z-index: 999;
  padding: 1rem;
  margin: 1rem;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
}

#arrow {
  visibility: hidden;
}

#arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}

.popper[data-popper-placement^="top"] > #arrow {
  bottom: -4px;
}

.popper[data-popper-placement^="bottom"] > #arrow {
  top: -4px;
}

.popper[data-popper-placement^="left"] > #arrow {
  right: -4px;
}

.popper[data-popper-placement^="right"] > #arrow {
  left: -4px;
}

.menu_seperator {
  margin: 1rem 0rem;
  border-bottom: 1px solid #ddd;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  margin-right: 0.5rem;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 17px;
}

.slider.round:before {
  border-radius: 50%;
}

.save-filters-button,
.clear-filters-button {
  background-color: rgb(238, 238, 238);
  border-radius: 0.2rem;
  padding: 0.4rem;
  position: relative;
  font-size: 0.7rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  border: none;
}
