.incoming-call-bar {
    display: flex;
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 0.9rem;
    border-radius: 0.5rem;
    font-size: 65%;
    width: 300px;
    margin: 1rem;
    z-index: 999;
    color: white;
}

.incoming-call-bar h3 {
    margin: 0.3rem 0;
}

.call-info {
    display: flex;
    flex-direction: column;
    flex: 2;
    gap: 0.2rem;
}

.call-buttons {
    display: flex;
    gap: 0.5rem;
}

.call-button {
    all: unset;
    cursor: pointer;
}

.call-button {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0.6rem;
    border-radius: 50%;
    background-color:rgba(0, 0, 0, 0.6);
    width: 1.25rem;
    height: 1.25rem;
}

.call-button:hover {
    opacity: .8;
}

.answer-button {
    background-color: rgb(73, 235, 89);
    height: 20px;
}

.answer-button > svg {
    transform: rotate(240deg);
}

.hangup-button {
    background-color: rgb(228, 84, 84);
    height: 20px;
}

.incoming-text {
    font-size: 0.75rem;
    margin: 0.2rem 0;
    font-weight: 300;
}

.caller-name {
    margin: 0;
    font-size: .9rem;
    font-weight: 400;
}

.muted > * {
    filter: invert(43%) sepia(87%) saturate(2136%) hue-rotate(339deg) brightness(100%) contrast(103%);
}

/* 

.answer, .hangup, .mute {
    border: none;
    border-radius: 50%;
    height: 40px;
    width: 40px
}

.answer:hover, .hangup:hover, .mute:hover {
    opacity: .8
}

.answer {
    background-color: rgb(38, 203, 54);
}

.hangup {
    background-color: rgb(217, 44, 44);
}

.mute {
    background-color: rgb(69, 69, 69);
}

.muted {
    background-color: rgba(255, 255, 266, 0.4);
}


.call-icon:hover {
    opacity: 0.5
} */

