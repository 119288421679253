.pipelineHeader {
  display: flex;
  /* justify-content: space-between; */
  padding-bottom: 0.5rem;
  margin: 0 auto;
  z-index: 999;
  margin-bottom: 2rem;
}

.title {
  margin: 0;
  padding: 0;
  flex: 2;
}

.title h3 {
  margin: 0;
  padding: 0;
}

.title {
  display: flex;
  align-items: center;
  gap: .25rem;
}

.dropdownArrow {
  display: flex;
  align-items: center;
}

.wrapper {
  display: flex;
  align-items: center;
}

.wrapper:hover {
  cursor: pointer;
}

.popper {
  opacity: 1;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0.2rem;
  z-index: 999;
  box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
  min-width: 250px;
}

.actions {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.viewMode {
  display: flex;
  gap: .25rem;
}

.kanban, .list {
  display: flex;
  justify-content: center; 
  align-items: center;
}

.kanban:hover, .list:hover {
  cursor: pointer;
}

.kanban svg, .list svg {
  pointer-events: none;
}

.list { 
  transform: rotate(90deg);
}

.viewModeText {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  font-size: .8rem;
  margin-left: .25rem;
}

.kebabMenu {
  transform: rotate(90deg);
}

.kebabMenu:hover {
  cursor: pointer;
}

.editPipelineMenu {
  list-style: none;
  padding: 0;
  margin: 0;
  padding: .3rem;
}

.editPipelineMenu li {
  list-style: none;
  margin: 0;
  padding: .5rem;
}

.menuItem {
  display: flex;
  align-items: center;
  gap: .5rem;
  font-size: .8rem;
  padding: .25rem;
  border-radius: .25rem;
}

.menuItem:hover {
  background-color: var(--MediumGray-color);
  overflow: hidden;
  cursor: pointer;
}

.trash-icon, .edit-icon {
  cursor: pointer;
  color: gray;
}

.active {
  background-color: rgba(0, 0, 0, 0.03);
}
