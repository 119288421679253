.close {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 0.2rem;
  transition: 200ms all;
}

.close:hover {
  background-color: rgba(228, 228, 228, 0.481);
  cursor: pointer;
}

.menuSeparator {
  margin: 0.5rem 0rem;
  border-bottom: 1px solid #ddd;
}


.left {
  display: flex;
  flex-direction: column;
  width: 70%;
  border-left: 1px solid rgba(0, 0, 0, 0.06);
  border-right: 1px solid rgba(0, 0, 0, 0.06);
  /* gap: 1rem; */
  /* background-color: rgba(0,0,0,0.05); */
  overflow: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.left h3 {
  margin: 0;
}

.title {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-weight: bold;
  padding: 1rem 1rem 0.5rem 1rem;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.06); */
  position: sticky;
  top: 0;
  background: white;
}

.contacts {
  display: flex;
  flex-direction: column;
}

.contacts>div:first-child {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.contact {
  padding: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  transition: all 200ms ease-in;
}

.contact:hover {
  background-color: var(--MediumGray-color);
  cursor: pointer;
}

.contact.active {
  background-color: var(--MediumGray-color);
}

.contactDetails {
  display: flex;
  align-items: center;
  gap: 0.25rem;
}

.contactNameLastMessage,
.nameEmail {
  display: flex;
  flex: 2 1;
  flex-direction: column;
  font-size: 0.9rem;
  font-weight: 500;
  gap: 0.35rem;
}

.contactName {
  font-size: 0.9rem;
  font-weight: 500;
}

.IconlastNMessage {
  display: flex;
  font-size: 0.75rem;
  gap: 0.25rem;
}

.icon>img {
  width: 0.8rem;
  height: 0.8rem;
}

.lastMessage {
  color: gray;
}

.lastMessageTime {
  font-size: 0.8rem;
}

.right {
  width: 100%;
  overflow: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.right::-webkit-scrollbar,
.left::-webkit-scrollbar {
  display: none;
}

.header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding: 1rem 1rem 0.5rem 1rem;
  position: sticky;
  top: 0;
  background: white;
}

.noMessages {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem;
}

.noMessagesIcon {
  color: rgba(0, 0, 0, .15)
}

.noMessages h4 {
  margin: 0;
}

.noMessage {
  text-align: center;
  color: gray;
}

.contactEmail {
  font-size: 0.8rem;
  color: gray;
}

.nameEmail {
  flex: 2;
}

.actions {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.actions > div:nth-child(3) {
  margin-left: 1rem;
}

