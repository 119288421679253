.button {
  position: relative;
  border: none;
  border-radius: 0.5rem;
  background-color: black;
  transition: opacity 0.3s ease-in;
  display: inline-block;
  margin: 0.5rem 0rem;
  height: 40px;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  width: 100%;
  font-size: 0.85rem;
}

.button__text {
  transition: all 0.2s;
}

.button--loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.button.button--loading::after {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

.button:hover {
  opacity: 0.8;
  cursor: pointer;
}

.button.button--red {
  background-color: transparent;
  border: 1px solid #d1453b;
  color: #d1453b;
}

.button.button--yellow {
  background-color: rgb(253, 243, 107);
  color: white;
}

.button.button--green {
  background-color: rgb(118, 234, 130);
  color: white;
}

.button--gray {
  background-color: var(--MediumGray-color);
  color: rgb(142, 142, 142);
}

.button--blue {
  background-color: white;
}

.button--white {
  background-color: transparent;
  border: 1px solid lightgray;
}

.button--white:hover {
  background-color: lightgray;
  color: white;
  cursor: pointer;
}

.button:disabled {
  background-color: lightgray;
  color: darkgray;
  text-shadow: none;
}

.button:disabled:hover {
  opacity: 1;
  cursor: not-allowed;
}

.button.button--large {
  width: 180px;
}

.button.button--medium {
  width: 140px;
}

.button.button--small {
  width: 100px;
}

.button--right {
  float: right;
}

@media only screen and (min-width: 768px) {
  .button--blue {
    background-color: #3068ce;
    color: white;
  }
}

/*Animations*/
@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}
