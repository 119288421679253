.notes__list-header {
    display: flex;
    justify-content: space-around;
    font-size: .8rem;
    color: rgb(143, 143, 143);
    text-align: center;
}

.notes__list-header > div {
    flex: 1;
    border-bottom: 1px solid rgba(240, 240, 240);
    padding-bottom: 0.5rem;
}

.notes__list-header > div:hover {
    cursor: pointer;   
}

.notes__list-header > .current-tab {
    font-weight: bold;
    color: black;
    border-bottom: 1px solid black;
}